
import React,{useState} from "react";
import { CustomerDomainName } from "../GlobalData/GlobalData";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobe,
  faInfoCircle,
  faPerson,
  faCar
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
function TransferCard({TransferData}){
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const Picurl=CustomerDomainName();
  const navigate=useNavigate();
  const BookTransfer=(index)=>{
    sessionStorage.setItem('TransferIndex', index);
    navigate('/transfer-checkout');
  };

  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      var baseprice =price;
    } else {
      const conversionrate = GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency];
      var newprice = (Number(conversionrate) * Number(price)).toFixed(0);
      var baseprice =newprice;
     
    }
    return baseprice
  };


    return(
        <>
        <div>
        <div className='filter-page__content'>
            <div className='filter-item-wrapper' id='tours_filter'>
              {TransferData.map((item, index) => (
                <div key={index} className='row'>
                  <div className='col-md-12 mb-4'>
                    <div className='row parent_row pt-0'>
                    <div className='transfer-card-top'  style={{ background: 'aliceblue',justifyContent:'space-between' }}>
                        <h5 className='card-title m-2'>
                        {item.vehicle_Name} ({item.transfer_type})                        
                        </h5>
                        <h5 style={{color:'cadetblue'}} className='card-title m-2'>Provider : {item.transfer_supplier}</h5>
                      </div>
                      <div className='col-md-3 item-from'>
                      <div>
                            <img
                              className='tour-img'
                              src={Picurl+'public/uploads/package_imgs/'+item.vehicle_image }
                              alt=''
                            />
                          </div>
                      </div>

                      <div className='col-md-6'>
                        <h6 className='departure-date mb-0'>
                         Date : {item.pickup_date}{' '}
                        </h6>
                        <p className="fw-bold">Pick-Up</p>

                          <div class='item-address'>
                            <i class='awe-icon awe-icon-marker-2'>
                              <FontAwesomeIcon icon={faGlobe} />
                            </i>{' '}
                            {item.pickup_City}{' '}
                          </div>
                          <p className="fw-bold">Drop-Of</p>
                          <div class='item-address'>
                            <i class='awe-icon awe-icon-marker-2'>
                              <FontAwesomeIcon icon={faGlobe} />
                            </i>{' '}
                            {item.dropof_City}{' '}
                          </div>
                          <div class='row'>
                          <div
                                className=' col-sm-6 col-6 col-md-6 col-lg-6 mt-1'
                              >
                                <div class='single-tour-feature d-flex align-items-center mb-3'>
                                  <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                    <i class='fas fa-check'>
                                      <FontAwesomeIcon icon={faPerson} />
                                    </i>
                                  </div>
                                  <div class='single-feature-titles'>
                                    <p class='title fw-bold'>Passengers</p>
                                    <p class='title fw-bold'>{item.search_passenger}</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className=' col-sm-6 col-6 col-md-6 col-lg-6 mt-1'
                              >
                                <div class='single-tour-feature d-flex align-items-center mb-3'>
                                  <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                    <i class='fas fa-check'>
                                      <FontAwesomeIcon icon={faCar} />
                                    </i>
                                  </div>
                                  <div class='single-feature-titles'>
                                    <p class='title fw-bold'>Vehicles</p>
                                    <p class='title fw-bold'>{item.no_of_vehicles}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                        {/* {hotelDetails[item.hotel_id] ? (
                          <div class='row'>
                            {hotelDetails[
                              item.hotel_id
                            ].details_data.facilities.map((facility, index) => (
                              <div
                                key={index}
                                className=' col-sm-4 col-4 col-md-4 col-lg-4 mt-1'
                              >
                                <div class='single-tour-feature d-flex align-items-center mb-3'>
                                  <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                    <i class='fas fa-check'>
                                      <FontAwesomeIcon icon={faCheck} />
                                    </i>
                                  </div>
                                  <div class='single-feature-titles'>
                                    <p class='title fw-bold'>{facility}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ):(
                        <div className='placeholder-glow'>
                          <span className='placeholder col-12'></span>
                          <span className='placeholder col-12'></span>
                          <span className='placeholder col-6'></span>

                        </div>

                        )} */}
                        
                        {/* <div class='item-address' style={{ color: 'green' }}>

                          <i class='awe-icon awe-icon-marker-2'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>{' '}
                          {item.rooms_options.length} Rooms Available{' '}
                        </div> */}
                      </div>
                      <div className='col-md-3 text-center card-price-section  pt-2'>
                        {/* <h6 className='text-center p-view-detail'>Map View</h6> */}
                        <div className='price text-center p-card-price'>
                          <h6><super>{CurrencyRates===undefined ? item.sale_currency:CurrencyRates.selectedcurrency} {renderPrice(Number(item.total_fare_markup))}</super></h6>
                        </div>
                        <div className="mt-2" style={{ fontSize: '11px' }}>
                                                  <FontAwesomeIcon
                                                    color='#bd1c1cc9'
                                                    icon={faInfoCircle}
                                                  />{' '}
                                                  Inclusive of VAT and Taxes
                                                </div>
                        <button
                          className='btn btn-primary select-styling search-btn1 form-control'
                          onClick={()=>BookTransfer(index)}
                        >
                          Book Now
                        </button>
                      </div>
                      {/* <div className='transfer-card-top'  style={{ background: 'aliceblue',justifyContent:'space-between' }}>
                        <h5 className='card-title m-2'>
                        {item.vehicle_Name}                        
                        </h5>
                        <div className="d-flex p-2">
                        <h5 style={{color:'cadetblue'}} className='card-title m-2'>{item.sale_currency} {item.total_fare_markup}</h5>
                        <button
                          className='btn btn-success btn-sm'
                          
                        >
                          Book Now
                        </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        </>
    );
}

export default TransferCard;