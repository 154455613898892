import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faPlaneDeparture,
  faPlaneArrival,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Axios from 'axios'
import { ViewTourDetail } from '../../Redux/Actions/actions'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../Loading/Loader'
import {
  CustomerDomainName,
  Hotelapitoken,
  ApiEndPoint
} from '../GlobalData/GlobalData'
function PackageDetailCard ({ filterData }) {
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)
  const [FilterData, setFilterData] = useState([])
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const imageurl = CustomerDomainName()
  const TourData = useSelector(state => state.hotels.toursdetail);
  const ToursDetail =TourData.tours.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
  var apiendpoint = ApiEndPoint();

  const fetchViewDetail = async (event, id, otherprovider,name) => {
    event.preventDefault()
    var apitoken = Hotelapitoken()
    setLoading(true)
    if (otherprovider === 0) {
      var data = {
        token: apitoken,
        id: id,
        type: 'tour',
        provider_token: ''
      }
    } else {
      var data = {
        token: apitoken,
        id: id,
        type: 'tour',
        provider_token: otherprovider
      }
    }

    try {
      const response = await Axios.post(
        apiendpoint + '/api/get_tour_details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      Dispatch(ViewTourDetail(response.data));
      const result = name.split(' ').join('-');
      navigation(`/umrah-package/${result}`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    DataFilter()
  }, [filterData])

  const DataFilter = () => {
    if (filterData !== '') {
      if (filterData.type === 'price') {
        const filteredTours = ToursDetail.filter(tour => {
          const price = tour.quad_grand_total_amount
          return price >= filterData.min && price <= filterData.max
        })
        setFilterData(filteredTours)
        setShowFilter(false)
      } else if (filterData.type === 'Stars') {
        if (
          filterData.rating1 === '0' &&
          filterData.rating2 === '0' &&
          filterData.rating3 === '0' &&
          filterData.rating4 === '0' &&
          filterData.rating5 === '0'
        ) {
          setFilterData(ToursDetail)
          setShowFilter(false)
        } else {
          const filteredTours = ToursDetail.filter(tour => {
            const hotelRating = tour.starts_rating
            if (hotelRating !== '') {
              return Object.keys(filterData).some(
                ratingKey =>
                  Number(hotelRating) === Number(filterData[ratingKey])
              )
            }
          })
          setFilterData(filteredTours)
          setShowFilter(false)
        }
      }else if (filterData.type === 'airport') {
        var length= Object.keys(filterData).length;
        if(length === 1){
          setFilterData(ToursDetail)
          setShowFilter(false)
        }else{
          
          const filteredTours = ToursDetail.filter(tour => {
            var flightdetail=JSON.parse(tour.flights_details);
           
            var name=flightdetail===null ? '' : flightdetail[0].departure_airport_code;
  
            return Object.keys(filterData).some(
              ratingKey =>
              name === filterData[ratingKey]
            )        })
          setFilterData(filteredTours)
          setShowFilter(false)
        }
       
      }
    }
  };
  
  return (
    <>
      {loading && <Loading />}
      {showFilter ? (
        <div className='filter-page__content'>
          <div className='filter-item-wrapper' id='tours_filter'>
            <div className='row'>
              <div className='col-md-12 mb-4'>
                {ToursDetail.map((item, index) => {
                  const flightDetails = JSON.parse(item.flights_details)
                  return (
                  <div key={index} className='wow animate__animated animate__slideInRight mt-2'
                    data-wow-duration="1s"
                    data-wow-delay="0.2s">
                      <div className='row parent_row ipmBhE hZjqDq fIdRda'>
                        <div className='col-md-3 item-from p-0'>
                          <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 gLnKRh dgoPHI">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 eMuljS brHOWu" width="1" height="100%">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 ImageSection__DynamicAbsolute-sc-8eqgic-0 djhuzv cCaSNq cPqfpR">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djwVDp" width="1">
                                  <div aria-label="Click to open popover with more information" type="button" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                    <button aria-label="Sign in to save" data-testid="HEART_SAVE_CONTAINER" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV HeartSaveButton__HeartSaveContainer-sc-1o6sggt-0 hItZFh" color="primary">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                        <div data-testid="ANIMATED_HEART_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 AnimatedHeartIcon__AnimatedHeartContainer-sc-aao2eh-0 djhuzv jEqlGx">
                                          <svg viewBox="0 0 560 560" class="heart-svg">
                                            <path class="heart" transform="translate(25, 25)" d="M256,96.5l-19.7-20.3C186.1,24.3,104.5,15.9,49.7,62.6c-62.8,53.6-66.1,149.8-9.9,207.9l193.5,199.8 c12.5,12.9,32.8,12.9,45.3,0l193.5-199.8c56.3-58.1,53-154.3-9.8-207.9l0,0C407.5,15.9,326,24.3,275.7,76.2L256,96.5z"></path>
                                          </svg>
                                          <span class="heart-burst"></span>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div style={{borderRadius:'16px 0px 0px 16px'}} class="BackgroundImage-pcln-design-system__sc-1eaij4d-0 ImageDisplay__ClickableImage-sc-1q7kzz5-1 gDxjVM eaVPxX" width="1" data-testid="CLICKABLE_IMAGE_WRAPPER">
                                <div style={{borderRadius:'16px 0px 0px 16px'}} class="InlineImageSwipe__Container-sc-zl6cca-0 kwHIxs">
                                  <div style={{borderRadius:'16px 0px 0px 16px'}} class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 PhotoSwipe__CarouselContainer-sc-1cx2tme-2 juFVep dgoPHI erKYis" data-testid="CAROUSEL_CONTAINER" width="1" role="region" aria-label="Photo carousel">
                                    <div>
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 PhotoCarousel__PhotoCarouselAbsolute-sc-engw3c-0 hLLYkz dpgIZ eNvHXq">
                                        <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                          <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                          <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ hero-image" 
                                                src={imageurl + 'public/uploads/package_imgs/' + item.tour_banner_image} alt="Exterior View" data-testid="MainImage" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__CarouselContainer-sc-9e4onp-0 djhuzv brHOWu dRiUBG PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa">
                                      <div data-unit-id="CAROUSEL_SLIDER" class="CarouselPagination__StyledCarousel-sc-9e4onp-1 gzvUFf">
                                        <form>
                                          <div data-unit-id="PAGINATION_DOTS_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__StyledFlex-sc-9e4onp-2 djhuzv ipCoSW mDstq">
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #1" aria-current="true" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 gfMkOK">
                                                <div color="text.lightest" width="8px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 cefYII hXamFu dlexSF"></div>
                                              </button>
                                            </div>
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #2" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                              </button>
                                            </div>
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #3" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                              </button>
                                            </div>
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #4" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                              </button>
                                            </div>
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #5" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 py-2 px-3'>
                          <h5 className='card-title mt-2'>
                            <a  onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='p-card-title'>
                              {item.title}
                            </a>
                          </h5>
                          <p className='departure-date my-2' style={{fontSize:'14px'}}>
                            <i className='fa-solid fa-plane-departure'>
                              <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                            </i>
                            {moment(item.start_date).format('LL')}{' '}
                          </p>
                          <p className='tour-description'>{item.content}</p>
                          <hr />
                          <p className='card-star'>
                            {item.starts_rating === 'No_Rating' ? (
                              <span className='fw-bold'>No Rating</span>
                            ) : (
                              <>
                                {item.starts_rating}.0
                                {Array.from({
                                  length: parseInt(item.starts_rating, 10)
                                }).map((_, index) => (
                                  <i key={index} className='fa fa-star'>
                                    {' '}
                                    <FontAwesomeIcon icon={faStar} />
                                  </i>
                                ))}
                              </>
                            )}
                          </p>
                          <div className='row f-13'>
                            <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                              <p>
                                <i className='fa-solid fa-plane-departure'>
                                  <FontAwesomeIcon icon={faPlaneDeparture} />
                                </i>{' '}
                                Departure From{' '}
                              </p>
                            </div>
                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                              <p>
                                {' '}
                                {flightDetails === null ? '':flightDetails[0].departure_airport_code} &nbsp;
                              </p>
                            </div>
                            <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                              <p>
                                <i className='fa-solid fa-plane-arrival'>
                                  <FontAwesomeIcon icon={faPlaneArrival} />
                                </i>{' '}
                                Return Date{' '}
                              </p>
                            </div>
                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                              <p>
                                {' '}
                                {moment(item.end_date).format('LL')} &nbsp;
                              </p>
                            </div>
                            <div className='col-md-5 col-5 col-sm-5 mt-1  p-car-departure'>
                              <p>
                                <i className='fa-solid fa-check'>
                                  <FontAwesomeIcon icon={faCheck} />
                                </i>{' '}
                                Available Spaces{' '}
                              </p>
                            </div>
                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                              <p> {item.no_of_pax_days} &nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3 casfcL p-2'>
                          <div className='text-center card-price-section hIzCYw fUSPHi'>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv iysiIk MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kJGIQs">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv MinRateMerchandising__PaddedMerchandisingTypes-sc-1tcss2r-0 XehgS"></div>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gHDiTU brHOWu" style={{textAlign:'center'}}>
                                  <div color="pricePrimary.base" font-weight="bold" class="ijknVs text-center"> 10% Off</div>
                                </div>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ezJlZN dBEOEE">
                                <div font-size="0" color="text.light" data-unit-id="SECTION_SUBTITLE" class="Text-pcln-design-system__sc-1xtb652-0 SavingsDisplay__SubTitleText-sc-1o04y33-0 HkSpC csvZTM">Savings last for a limited time only</div>
                              </div>
                            </div>
                            <div className='ggTnOm pb-2'>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv feTjmC">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ipCoSW">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK"></div>
                                  <span color="pricePrimary.base" font-weight="bold" font-size="0,,,2" class="Text__Span-pcln-design-system__sc-1xtb652-1 GenericPrice__Currency-sc-10yzufv-0 hSCRIz jRHxKY"> {item.currency_symbol}</span>
                                  <span color="pricePrimary.base" font-size="4,,,5" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 hPtDTS">  {item.quad_grand_total_amount !== null
                                        ? item.quad_grand_total_amount
                                        : item.triple_grand_total_amount !== null
                                        ? item.triple_grand_total_amount
                                        : item.double_grand_total_amount !== null
                                        ? item.double_grand_total_amount
                                        : 'N/A'}  
                                    </span>
                                </div>
                              </div>
                              <div className='time_length mt-2'>
                                <i className='fa fa-moon-o' aria-hidden='true'></i>
                                Tour Length{' '}
                                <span className='tour_length'>
                                  {item.time_duration} Night
                                </span>
                              </div>
                              <h6
                                onClick={event => fetchViewDetail(event, item.id,0,item.title)}
                                className='text-center  p-view-detail mt-2'
                              >
                                View Details
                              </h6>
                              <button onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='my-1 kOMYfA'>Book Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className='mt-3'> <h5 className='flight-heading'> Packages From Other Providers</h5></div>
                {TourData.other_providers_tours.map((provider, index) => (
                  <div key={index}>
                    {provider[0].map((item, index) => {
                      const flightDetails = JSON.parse(item.flights_details)
                      return (
                        <div key={index} className='mt-2'>
                          <div className='row parent_row ipmBhE hZjqDq fIdRda'>
                            <div className='col-md-3 item-from p-0'>
                              <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 gLnKRh dgoPHI">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 eMuljS brHOWu" width="1" height="100%">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 ImageSection__DynamicAbsolute-sc-8eqgic-0 djhuzv cCaSNq cPqfpR">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djwVDp" width="1">
                                      <div aria-label="Click to open popover with more information" type="button" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                        <button aria-label="Sign in to save" data-testid="HEART_SAVE_CONTAINER" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV HeartSaveButton__HeartSaveContainer-sc-1o6sggt-0 hItZFh" color="primary">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                            <div data-testid="ANIMATED_HEART_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 AnimatedHeartIcon__AnimatedHeartContainer-sc-aao2eh-0 djhuzv jEqlGx">
                                              <svg viewBox="0 0 560 560" class="heart-svg">
                                                <path class="heart" transform="translate(25, 25)" d="M256,96.5l-19.7-20.3C186.1,24.3,104.5,15.9,49.7,62.6c-62.8,53.6-66.1,149.8-9.9,207.9l193.5,199.8 c12.5,12.9,32.8,12.9,45.3,0l193.5-199.8c56.3-58.1,53-154.3-9.8-207.9l0,0C407.5,15.9,326,24.3,275.7,76.2L256,96.5z"></path>
                                              </svg>
                                              <span class="heart-burst"></span>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{borderRadius:'16px 0px 0px 16px'}} class="BackgroundImage-pcln-design-system__sc-1eaij4d-0 ImageDisplay__ClickableImage-sc-1q7kzz5-1 gDxjVM eaVPxX" width="1" data-testid="CLICKABLE_IMAGE_WRAPPER">
                                    <div style={{borderRadius:'16px 0px 0px 16px'}} class="InlineImageSwipe__Container-sc-zl6cca-0 kwHIxs">
                                      <div style={{borderRadius:'16px 0px 0px 16px'}} class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 PhotoSwipe__CarouselContainer-sc-1cx2tme-2 juFVep dgoPHI erKYis" data-testid="CAROUSEL_CONTAINER" width="1" role="region" aria-label="Photo carousel">
                                        <div>
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 PhotoCarousel__PhotoCarouselAbsolute-sc-engw3c-0 hLLYkz dpgIZ eNvHXq">
                                            <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                              <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                              <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ hero-image" 
                                                    src={provider[1].webiste_Address + '/public/uploads/package_imgs/' + item.tour_banner_image} alt="Exterior View" data-testid="MainImage" />
                                            </div>
                                          </div>
                                        </div>
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__CarouselContainer-sc-9e4onp-0 djhuzv brHOWu dRiUBG PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa">
                                          <div data-unit-id="CAROUSEL_SLIDER" class="CarouselPagination__StyledCarousel-sc-9e4onp-1 gzvUFf">
                                            <form>
                                              <div data-unit-id="PAGINATION_DOTS_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__StyledFlex-sc-9e4onp-2 djhuzv ipCoSW mDstq">
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #1" aria-current="true" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 gfMkOK">
                                                    <div color="text.lightest" width="8px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 cefYII hXamFu dlexSF"></div>
                                                  </button>
                                                </div>
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #2" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                    <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                  </button>
                                                </div>
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #3" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                    <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                  </button>
                                                </div>
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #4" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                    <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                  </button>
                                                </div>
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #5" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                    <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                  </button>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 py-2 px-3'>
                              <h5 className='card-title mt-2'>
                                <a href='#' className='p-card-title'>
                                  {item.title}
                                </a>
                              </h5>
                              <p className='departure-date my-2' style={{fontSize:'14px'}}>
                                <i className='fa-solid fa-plane-departure'>
                                  <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                                </i>
                                {moment(item.start_date).format('LL')}{' '}
                              </p>
                              <p className='tour-description'>{item.content}</p>
                              <hr />
                              <p className='card-star'>
                                {item.starts_rating === 'No_Rating' ? (
                                  <span className='fw-bold'>No Rating</span>
                                ) : (
                                  <>
                                    {item.starts_rating}.0
                                    {Array.from({
                                      length: parseInt(item.starts_rating, 10)
                                    }).map((_, index) => (
                                      <i key={index} className='fa fa-star'>
                                        {' '}
                                        <FontAwesomeIcon icon={faStar} />
                                      </i>
                                    ))}
                                  </>
                                )}
                              </p>
                              <div className='row f-13'>
                                <div className='col-md-5 col-6 mt-1 p-car-departure'>
                                  <p>
                                    <i className='fa-solid fa-plane-departure'>
                                      <FontAwesomeIcon
                                        icon={faPlaneDeparture}
                                      />
                                    </i>{' '}
                                    Departure From{' '}
                                  </p>
                                </div>
                                <div className='col-md-7 col-6 mt-1 p-car-departure'>
                                  <p>
                                    {' '}
                                    {
                                    flightDetails===null ? '' : flightDetails[0].departure_airport_code
                                    }{' '}
                                    &nbsp;
                                  </p>
                                </div>
                                <div className='col-md-5 col-6 mt-1 p-car-departure'>
                                  <p>
                                    <i className='fa-solid fa-plane-arrival'>
                                      <FontAwesomeIcon icon={faPlaneArrival} />
                                    </i>{' '}
                                    Return Date{' '}
                                  </p>
                                </div>
                                <div className='col-md-7 col-6 mt-1 p-car-departure'>
                                  <p>
                                    {' '}
                                    {moment(item.end_date).format('LL')} &nbsp;
                                  </p>
                                </div>
                                <div className='col-md-5 col-6 mt-1  p-car-departure'>
                                  <p>
                                    <i className='fa-solid fa-check'>
                                      <FontAwesomeIcon icon={faCheck} />
                                    </i>{' '}
                                    Available Spaces{' '}
                                  </p>
                                </div>
                                <div className='col-md-7 col-6 mt-1 p-car-departure'>
                                  <p> {item.no_of_pax_days} &nbsp;</p>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-3 casfcL p-2'>
                              <div className='text-center card-price-section hIzCYw fUSPHi'>
                                <div></div>
                                <div className='ggTnOm pb-2'>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv feTjmC">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ipCoSW">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK"></div>
                                      <span color="pricePrimary.base" font-weight="bold" font-size="0,,,2" class="Text__Span-pcln-design-system__sc-1xtb652-1 GenericPrice__Currency-sc-10yzufv-0 hSCRIz jRHxKY"> {item.currency_symbol}</span>
                                      <span color="pricePrimary.base" font-size="4,,,5" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 hPtDTS">{item.double_grand_total_amount} <sub>PP</sub></span>
                                    </div>
                                  </div>
                                  <div className='time_length mt-2'>
                                    <i
                                      className='fa fa-moon-o'
                                      aria-hidden='true'
                                    ></i>
                                    Tour Length{' '}
                                    <span className='tour_length'>
                                      {item.time_duration} Night
                                    </span>
                                  </div>
                                  <h6 className='text-center p-view-detail mt-2'>
                                    <NavLink className='nav-link' to='/view-detail'>
                                      View Details
                                    </NavLink>
                                  </h6>
                                  <button className='my-1 kOMYfA'>Book Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='filter-page__content'>
          <div className='filter-item-wrapper' id='tours_filter'>
            <div className='row'>
              <div className='col-md-12 mb-4'>
                {FilterData.map((item, index) => {
                  const flightDetails = JSON.parse(item.flights_details)
                  return (
                    <div key={index} className='mt-2'>
                      <div className='row parent_row ipmBhE hZjqDq fIdRda'>
                        <div className='col-md-3 item-from p-0'>
                          <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 gLnKRh dgoPHI">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 eMuljS brHOWu" width="1" height="100%">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 ImageSection__DynamicAbsolute-sc-8eqgic-0 djhuzv cCaSNq cPqfpR">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djwVDp" width="1">
                                  <div aria-label="Click to open popover with more information" type="button" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                    <button aria-label="Sign in to save" data-testid="HEART_SAVE_CONTAINER" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV HeartSaveButton__HeartSaveContainer-sc-1o6sggt-0 hItZFh" color="primary">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                        <div data-testid="ANIMATED_HEART_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 AnimatedHeartIcon__AnimatedHeartContainer-sc-aao2eh-0 djhuzv jEqlGx">
                                          <svg viewBox="0 0 560 560" class="heart-svg">
                                            <path class="heart" transform="translate(25, 25)" d="M256,96.5l-19.7-20.3C186.1,24.3,104.5,15.9,49.7,62.6c-62.8,53.6-66.1,149.8-9.9,207.9l193.5,199.8 c12.5,12.9,32.8,12.9,45.3,0l193.5-199.8c56.3-58.1,53-154.3-9.8-207.9l0,0C407.5,15.9,326,24.3,275.7,76.2L256,96.5z"></path>
                                          </svg>
                                          <span class="heart-burst"></span>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div style={{borderRadius:'16px 0px 0px 16px'}} class="BackgroundImage-pcln-design-system__sc-1eaij4d-0 ImageDisplay__ClickableImage-sc-1q7kzz5-1 gDxjVM eaVPxX" width="1" data-testid="CLICKABLE_IMAGE_WRAPPER">
                                <div style={{borderRadius:'16px 0px 0px 16px'}} class="InlineImageSwipe__Container-sc-zl6cca-0 kwHIxs">
                                  <div style={{borderRadius:'16px 0px 0px 16px'}} class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 PhotoSwipe__CarouselContainer-sc-1cx2tme-2 juFVep dgoPHI erKYis" data-testid="CAROUSEL_CONTAINER" width="1" role="region" aria-label="Photo carousel">
                                    <div>
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 PhotoCarousel__PhotoCarouselAbsolute-sc-engw3c-0 hLLYkz dpgIZ eNvHXq">
                                        <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                          <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                          <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ hero-image" 
                                                src={imageurl + 'public/uploads/package_imgs/' + item.tour_banner_image} alt="Exterior View" data-testid="MainImage" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__CarouselContainer-sc-9e4onp-0 djhuzv brHOWu dRiUBG PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa">
                                      <div data-unit-id="CAROUSEL_SLIDER" class="CarouselPagination__StyledCarousel-sc-9e4onp-1 gzvUFf">
                                        <form>
                                          <div data-unit-id="PAGINATION_DOTS_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__StyledFlex-sc-9e4onp-2 djhuzv ipCoSW mDstq">
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #1" aria-current="true" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 gfMkOK">
                                                <div color="text.lightest" width="8px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 cefYII hXamFu dlexSF"></div>
                                              </button>
                                            </div>
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #2" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                              </button>
                                            </div>
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #3" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                              </button>
                                            </div>
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #4" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                              </button>
                                            </div>
                                            <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                              <button aria-label="Go to Image #5" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 py-2 px-3'>
                          <h5 className='card-title mt-2'>
                            <a  onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='p-card-title'>
                              {item.title}
                            </a>
                          </h5>
                          <p className='departure-date my-2' style={{fontSize:'14px'}}>
                            <i className='fa-solid fa-plane-departure'>
                              <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                            </i>
                            {moment(item.start_date).format('LL')}{' '}
                          </p>
                          <p className='tour-description'>{item.content}</p>
                          <hr />
                          <p className='card-star'>
                            {item.starts_rating === 'No_Rating' ? (
                              <span className='fw-bold'>No Rating</span>
                            ) : (
                              <>
                                {item.starts_rating}.0
                                {Array.from({
                                  length: parseInt(item.starts_rating, 10)
                                }).map((_, index) => (
                                  <i key={index} className='fa fa-star'>
                                    {' '}
                                    <FontAwesomeIcon icon={faStar} />
                                  </i>
                                ))}
                              </>
                            )}
                          </p>
                          <div className='row f-13'>
                            <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                              <p>
                                <i className='fa-solid fa-plane-departure'> <FontAwesomeIcon icon={faPlaneDeparture} /></i>{' '}
                                Departure From{' '}
                              </p>
                            </div>
                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                              <p>
                                {' '}
                                {flightDetails === null ? '':flightDetails[0].departure_airport_code} &nbsp;
                              </p>
                            </div>
                            <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                              <p>
                                <i className='fa-solid fa-plane-arrival'>
                                  <FontAwesomeIcon icon={faPlaneArrival} />
                                </i>{' '}
                                Return Date{' '}
                              </p>
                            </div>
                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                              <p>
                                {' '}
                                {moment(item.end_date).format('LL')} &nbsp;
                              </p>
                            </div>
                            <div className='col-md-5 col-5 col-sm-5 mt-1  p-car-departure'>
                              <p>
                                <i className='fa-solid fa-check'>
                                  <FontAwesomeIcon icon={faCheck} />
                                </i>{' '}
                                Available Spaces{' '}
                              </p>
                            </div>
                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                              <p> {item.no_of_pax_days} &nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3 casfcL p-2'>
                          <div className='text-center card-price-section hIzCYw fUSPHi'>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv iysiIk MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kJGIQs">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv MinRateMerchandising__PaddedMerchandisingTypes-sc-1tcss2r-0 XehgS"></div>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gHDiTU brHOWu" style={{textAlign:'center'}}>
                                  <div color="pricePrimary.base" font-weight="bold" class="ijknVs text-center"> 10% Off</div>
                                </div>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ezJlZN dBEOEE">
                                <div font-size="0" color="text.light" data-unit-id="SECTION_SUBTITLE" class="Text-pcln-design-system__sc-1xtb652-0 SavingsDisplay__SubTitleText-sc-1o04y33-0 HkSpC csvZTM">Savings last for a limited time only</div>
                              </div>
                            </div>
                            <div className='ggTnOm pb-2'>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv feTjmC">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ipCoSW">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK"></div>
                                  <span color="pricePrimary.base" font-weight="bold" font-size="0,,,2" class="Text__Span-pcln-design-system__sc-1xtb652-1 GenericPrice__Currency-sc-10yzufv-0 hSCRIz jRHxKY"> {item.currency_symbol}</span>
                                  <span color="pricePrimary.base" font-size="4,,,5" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 hPtDTS"> {item.quad_grand_total_amount}</span>
                                </div>
                              </div>
                              <div className='time_length mt-2'>
                                <i className='fa fa-moon-o' aria-hidden='true'></i>
                                   Tour Length{' '}
                                <span className='tour_length'> {item.time_duration} Night</span>
                              </div>
                              <h6
                                onClick={event => fetchViewDetail(event, item.id,0,item.title)}
                                className='text-center  p-view-detail mt-2'
                              >
                                View Details
                              </h6>
                              <button onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='my-1 kOMYfA'>Book Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PackageDetailCard
