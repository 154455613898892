import React from "react";
import { NavLink } from 'react-router-dom'
import logo from '../../Images/Logo/logo.png'

function Footer(){
    return(
    <>
        <div id="expr-ftr" class="sc-83b3ffc4-0 hqGofM experimental-footer jVAcwv">
          <div id="pcln-global-footer" style={{visibility:'hidden'}}>
            <div>
              <div class="page--responsive light-footer">  
                <footer id="footer-root" class="global-footer global-header-footer global-footer--home global-footer--customized ">
                  <div class="global-footer__outer">
                    <div class="global-footer__inner">  
                      <nav class="global-footer__top">
                        <div class="global-footer__top-column">
                           <strong class="global-footer__top-column__headline">Our products</strong>
                           <ul class="global-footer__top-column__list"> 
                             <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="/hotels?modal=express-deals" data-ga-category="footer" data-ga-action="product links" data-ga-label="hotel_express_deals">Hotel Express Deals<sup>™</sup></a>
                            </li>
                            <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="/hotels/" data-ga-category="footer" data-ga-action="product links" data-ga-label="hotels"> Hotels </a>
                            </li>   
                            <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="/rentalcars/" data-ga-category="footer" data-ga-action="product links" data-ga-label="cars"> Cars </a>
                            </li>   
                            <li class="global-footer__top-column__item">
                              <a id="flights-nav-footer-link" class="global-footer__top-column__item--anchor" href="/flights/" data-ga-category="footer" data-ga-action="product links" data-ga-label="flights"> Flights </a>
                            </li>   
                            <li class="global-footer__top-column__item">
                              <a id="link_footer_nav_vacationpackages" class="global-footer__top-column__item--anchor" href="/vacationpackages/" data-ga-category="footer" data-ga-action="product links" data-ga-label="packages"> Packages </a>
                            </li>  
                            <li class="global-footer__top-column__item">
                               <a class="global-footer__top-column__item--anchor" href="https://cruises.priceline.com/?utm_medium=partner_site_topnav&amp;utm_source=pclnhp_top_nav&amp;utm_campaign=globalnav&amp;utm_content=hp_top_nav_cruise" data-ga-category="footer" data-ga-action="product links" data-ga-label="cruises"> Cruises </a>
                            </li>   
                            <li class="global-footer__top-column__item">
                               <a class="global-footer__top-column__item--anchor" href="https://experiences.priceline.com?CID=hpgfooterdktp" data-ga-category="footer" data-ga-action="product links" data-ga-label="experiences"> Experiences </a>
                            </li>  
                            <li class="global-footer__top-column__item">
                               <a class="global-footer__top-column__item--anchor" href="https://cards.barclaycardus.com/banking/credit-card/priceline/visa-signature/banner/36c96d65-79d0-426a-85f3-9d295d7af24f?referrerid=FooterlinkSOS23" data-ga-category="footer" data-ga-action="product links" data-ga-label="priceline_reward_visa_card">Priceline VIP Rewards<sup>™</sup>Visa<sup>®</sup> Card </a>
                            </li>
                            <li class="global-footer__top-column__item">
                               <a class="global-footer__top-column__item--anchor" href="https://www.americanforcestravel.com/?utm_source=Footer&amp;utm_medium=PL&amp;utm_campaign=MIl&amp;wl_source=PLFooterMIl&amp;refclickid=PLFooterMIl" data-ga-category="footer" data-ga-action="product links" data-ga-label="priceline_military_members_discount_site"> Military Members Discounts  </a>
                            </li>
                            <li class="global-footer__top-column__item">
                               <a class="global-footer__top-column__item--anchor" href="https://www.americanforcestravel.com/?utm_source=Footer&amp;utm_medium=PL&amp;utm_campaign=Vet&amp;wl_source=PLFooterVet&amp;refclickid=PLFooterVet" data-ga-category="footer" data-ga-action="product links" data-ga-label="priceline_veterans_discount_site"> Veterans Discounts </a>
                            </li>
                           </ul>
                        </div>
                        <div class="global-footer__top-column">
                           <strong class="global-footer__top-column__headline">About Priceline</strong>
                           <ul class="global-footer__top-column__list">  
                              <li class="global-footer__top-column__item">
                                 <a class="global-footer__top-column__item--anchor" href="https://help.priceline.com" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="contact_us"> Contact Us </a>
                              </li>  
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="https://press.priceline.com/our-story/" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="our_story">Our Story</a>
                              </li>
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="https://careers.priceline.com/?referrerid=FOOTER" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="careers"> Careers </a>
                              </li>
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="https://press.priceline.com/?referrerid=FOOTER" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="press_center">Press Center</a>
                              </li>
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="https://www.priceline.com/partner/v2/summer-release-2023" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="new_features">Learn About New Features</a>
                              </li>
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="https://ir.bookingholdings.com/?referrerid=FOOTER" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="investor_relations"> Investor Relations </a>
                              </li>
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="/static-pages/privacy-policy.html?referrerid=FOOTER" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="privacy_policy"> Privacy Policy </a>
                              </li>
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="/static-pages/terms_en.html?referrerid=FOOTER" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="terms_and_conditions"> Terms and Conditions </a>
                              </li>
                              <li class="global-footer__top-column__item">
                                 <a class="global-footer__top-column__item--anchor" href="/partner/v2/corporate-contact-information" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="corporate_contact"> Corporate Contact Information </a>
                              </li>
                              <li class="global-footer__top-column__item">
                                 <a class="global-footer__top-column__item--anchor" href="/partner/v2/sustainable-travel" data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="sustainable_travel"> Sustainability </a>
                              </li>
                              <li class="global-footer__top-column__item global-footer__ccpa-link" style={{display:'none'}}>
                                 <a class="global-footer__top-column__item--anchor" rel="nofollow" href="/dsarwebform" data-ga-category="footer" data-ga-action="company links" data-ga-label="do_not_sell_my_info">Do Not Sell My Personal Information</a>
                              </li>
                           </ul>
                        </div>
                        <div class="global-footer__top-column">
                           <strong class="global-footer__top-column__headline">Partner with Priceline</strong>
                           <ul class="global-footer__top-column__list">
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="/join?referrerid=FOOTER" data-ga-category="footer" data-ga-action="partner links" data-ga-label="add_your_hotel"> Add Your Hotel </a>
                              </li>
                              <li class="global-footer__top-column__item">
                                <a class="global-footer__top-column__item--anchor" href="https://pricelinepartnersolutions.com/" data-ga-category="footer" data-ga-action="partner links" data-ga-label="priceline_partner_network">Priceline Partner Solutions </a>
                              </li>
                              <li class="global-footer__top-column__item">
                                 <a class="global-footer__top-column__item--anchor" href="/advertise?referrerid=FOOTER" data-ga-category="footer" data-ga-action="partner links" data-ga-label="advertise"> Advertise </a>
                              </li>
                           </ul>
                        </div>
                        <div class="global-footer__top-column global-footer__top-column--last">
                          <strong class="global-footer__top-column__headline">Connect with Priceline</strong>
                          <ul class="global-footer__top-column__list">
                            <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="https://itunes.apple.com/us/app/priceline-hotels-flights-cars/id336381998" target="_blank" data-ga-category="footer" data-ga-action="Footer_AppDownload" data-ga-label="Priceline for iOS">Priceline for iOS</a>
                            </li>
                            <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="https://play.google.com/store/apps/details?id=com.priceline.android.negotiator" target="_blank" data-ga-category="footer" data-ga-action="Footer_AppDownload" data-ga-label="Priceline for Android">Priceline for Android</a>
                            </li>
                            <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="https://www.facebook.com/priceline" target="_blank" data-ga-category="footer" data-ga-action="Social Media" data-ga-label="facebook">Facebook</a>
                            </li>
                            <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="https://www.instagram.com/priceline" target="_blank" data-ga-category="footer" data-ga-action="Social Media" data-ga-label="instagram">Instagram</a>
                            </li>
                            <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="https://twitter.com/priceline" target="_blank" data-ga-category="footer" data-ga-action="Social Media" data-ga-label="twitter">Twitter</a>
                            </li>
                            <li class="global-footer__top-column__item">
                              <a class="global-footer__top-column__item--anchor" href="https://www.youtube.com/user/priceline" target="_blank" data-ga-category="footer" data-ga-action="Social Media" data-ga-label="youtube">YouTube</a>
                            </li>
                          </ul>
                        </div>
                      </nav>  
                      <p class="global-footer__footnote">
                        <span class="global-footer__footnote__line global-footer__footnote--marks">PRICELINE, PRICELINE.COM, NAME YOUR OWN PRICE, EXPRESS DEALS, TONIGHT ONLY DEAL, and PRICEBREAKER are service marks or registered service marks of priceline.com LLC.</span>
                        <span class="global-footer__footnote__line global-footer__footnote__line--copyright"> All material herein © 1998-2024 priceline.com LLC, all rights reserved.</span>
                        <span class="global-footer__footnote__line global-footer__footnote__line--address">priceline.com LLC is located at 800 Connecticut Ave. Norwalk, CT 06854.</span>
                      </p>
                      <br />
                      <p class="global-footer__footnote global-footer__footnote--disclaimer">
                        <span>* Savings claim based on Express Deals® bookings compared to Priceline’s lowest retail rate for same itinerary. Express Deals® travel provider shown after booking.</span>
                        <br /><br />
                        <span>Package savings based on all flight and hotel itineraries booked together as a package, compared to price of same flight and hotel booked separately on priceline.com. Savings vary and may not be available for all packages.</span>
                      </p> 
                    </div>
                  </div>  
                  <aside class="global-footer__pcln-group-logos">
                    <div class="global-footer__pcln-group-logos__inner">
                      <p class="global-footer__pcln-group-logos__headline">
                        Priceline is part of Booking Holdings, the world leader in online travel &amp;&nbsp;related&nbsp;services.
                      </p>
                      <ul class="global-footer__pcln-group-logos__list">
                        <li class="global-footer__pcln-group-logos__item--priceline-svg global-footer__pcln-group-logos__item__shared">
                          <img src={logo} width={100} height={40} alt="Priceline" loading="lazy" style={{backgroundColor:'white', borderRadius:'20px', padding:'5px'}} />
                        </li>
                        <li class="global-footer__pcln-group-logos__item--booking-svg global-footer__pcln-group-logos__item__shared">
                          <img src="https://s1.pclncdn.com/design-assets/gns/booking-dot-com.svg" alt="Booking.com" loading="lazy" />
                        </li>
                        <li class="global-footer__pcln-group-logos__item--kayak-svg global-footer__pcln-group-logos__item__shared">
                          <img src="https://s1.pclncdn.com/design-assets/gns/kayak.svg" alt="Kayak" loading="lazy" />
                        </li>
                        <li class="global-footer__pcln-group-logos__item--agoda-svg global-footer__pcln-group-logos__item__shared">
                          <img src="https://s1.pclncdn.com/design-assets/gns/agoda.svg" alt="Agoda" loading="lazy" />
                        </li>
                        <li class="global-footer__pcln-group-logos__item--rentalcars-svg global-footer__pcln-group-logos__item__shared">
                          <img src="https://s1.pclncdn.com/design-assets/gns/rentalcars.svg" alt="Rentalcars.com" loading="lazy" />
                        </li>
                        <li class="global-footer__pcln-group-logos__item--opentable-svg global-footer__pcln-group-logos__item__shared">
                          <img src="https://s1.pclncdn.com/design-assets/gns/opentable.svg" alt="OpenTable" loading="lazy" />
                        </li>
                      </ul>
                    </div>
                  </aside> 
                </footer>  
              </div>
            </div>
          </div>
        </div>
    </>        
    );
}

export default Footer;