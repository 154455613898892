import React from 'react'
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Sofia"></link>

function OtherComponents () {
  return (
    <>
    <div class="sc-jIGnZt jVAcwv">
      <div class="sc-aXZVg ldwMXy">
        <div style={{overflow:'hidden'}} class="sc-aXZVg ldwMXy">
          <div class="sc-aXZVg sc-feUZmu gjRupo cxiIfM">
            <div class="sc-aXZVg sc-hmdomO ldwMXy grHEeX">
              <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
                <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                  <div class="sc-aXZVg sc-eqUAAy iqMbjt jAekbq">
                    <div class="sc-aXZVg sc-eqUAAy sc-gdyeKB llivTk oYJda Ptooe sc-cf2824f7-0 bcXIyJ sc-cf2824f7-0 bcXIyJ">
                      <div width="1,,0.5" class="sc-aXZVg sc-jEACwC sc-719b1c37-0 iCdfOp bXYFop dEdkZD">
                        <div height="100%" class="sc-aXZVg sc-eqUAAy MediaCard__ContainerFlex-sc-1dwmsnf-1 hwxHjM hsOoFy kdSzFt">
                          <div class="sc-aXZVg ldwMXy">
                            <div class="sc-aXZVg sc-kOHTFB ldwMXy fDkWMd">
                              <div class="sc-imWYAI sc-dkmUuB doJyA-D fVGdFZ MediaCard__BackgroundImage-sc-1dwmsnf-0 kLGKll" height="200px" data-testid="backgroundImage" loading="eager"></div>
                            </div>
                            <div class="sc-aXZVg kgMHOD">
                              <div color="text.light" font-weight="700" font-size="12px" letter-spacing="0.063rem" class="sc-fqkvVR iBXpzE">STAY, PLAY, PARADE</div>
                              <div color="text.dark" font-weight="700" font-size="20px" class="sc-fqkvVR elhLKq">Mardi Gras Vacation Deals</div>
                              <div color="text.base" font-weight="500" font-size="14px" letter-spacing="-0.003rem" class="sc-fqkvVR eqPnni">Stay in the heart of the celebration and save 15% or more on featured deals PLUS extra savings when you bundle.</div>
                            </div>
                          </div>
                          <div class="sc-aXZVg daarIs">
                            <div font-weight="500" font-size="14px" class="sc-fqkvVR cnrZBo">
                              <a aria-label="Mardi Gras 2024! Save 15% or more in New Orleans " color="primary" href="https://www.priceline.com/partner/v2/mardi-gras-vacation-deals-2024" target="_blank" rel="noopener" class="sc-jlZhew jhFlZt">
                                <div class="sc-fqkvVR czdWUD">Explore NOLA Deals </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div width="1,,0.5" class="sc-aXZVg sc-jEACwC sc-719b1c37-0 iCdfOp bXYFop dEdkZD">
                        <div height="100%" class="sc-aXZVg sc-eqUAAy MediaCard__ContainerFlex-sc-1dwmsnf-1 hwxHjM hsOoFy kdSzFt">
                          <div class="sc-aXZVg ldwMXy">
                            <div class="sc-aXZVg sc-kOHTFB ldwMXy fDkWMd">
                              <div class="sc-imWYAI sc-dkmUuB bWBXvu fVGdFZ MediaCard__BackgroundImage-sc-1dwmsnf-0 kLGKll" height="200px" data-testid="backgroundImage" loading="eager"></div>
                            </div>
                            <div class="sc-aXZVg kgMHOD">
                              <div color="text.light" font-weight="700" font-size="12px" letter-spacing="0.063rem" class="sc-fqkvVR iBXpzE">VALENTINE'S BLISS</div>
                              <div color="text.dark" font-weight="700" font-size="20px" class="sc-fqkvVR elhLKq">Unforgettable escapes!</div>
                              <div color="text.base" font-weight="500" font-size="14px" letter-spacing="-0.003rem" class="sc-fqkvVR eqPnni">Explore handpicked destinations &amp; create cherished memories. Save as much as $250 extra when you bundle. Terms apply. Use code: VALENTINEBLISS</div>
                            </div>
                          </div>
                          <div class="sc-aXZVg daarIs">
                          <div font-weight="500" font-size="14px" class="sc-fqkvVR cnrZBo">
                            <a aria-label="Save up to 40% on hand picked destinations when you bundle hotel + flight " color="primary" href="https://www.priceline.com/partner/v2/valentines-bliss" target="_blank" rel="noopener" class="sc-jlZhew jhFlZt">
                              <div class="sc-fqkvVR czdWUD">Explore Deals </div>
                            </a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                  <div class="sc-aXZVg cPFeQP">
                    <div class="sc-aXZVg sc-jEACwC OptInCard__OptInFormCard-sc-ji1nfw-0 fVrJIT jjRHoq dzwvKv">
                      <div class="sc-aXZVg sc-hmdomO ldwMXy lkjwMz">
                        <div class="sc-aXZVg sc-eqUAAy dUQmGt hIDAJO">
                          <div width="160px" class="sc-aXZVg dcGJoN">
                            <img alt="app screenshot" height="100%" src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/app-download/opt-in-app-download-square.png?opto&amp;auto=avif,webp" data-testid="desktop-image" loading="lazy" class="sc-kdBSHD OptInCard__OptInImage-sc-ji1nfw-1 jcWEqU evIdBZ" />
                          </div>
                          <div width="1" class="sc-aXZVg sc-eqUAAy feckNt fiYCwu">
                            <div class="sc-aXZVg ldwMXy">
                              <div color="text" font-weight="700" font-size="20px" class="sc-fqkvVR cSOqYT">Our Best Deals are in the App</div>
                              <div color="text" font-weight="500" font-size="14px" letter-spacing="-0.003rem" class="sc-fqkvVR eqPnni">Discover hotel, flight and rental car deals exclusively in the app. Download today to stay connected with important trip details.</div>
                            </div>
                            <div class="sc-aXZVg ldwMXy">
                              <div width="1" class="sc-aXZVg sc-eqUAAy eGwsri kmNFHk">
                                <div width="1" class="sc-aXZVg iyjFLz">
                                  <div class="sc-aXZVg sc-eqUAAy ldwMXy evpJHe">
                                    <div data-testid="icon-field-prefix-icons" class="sc-aXZVg sc-eqUAAy czUyOF hIDAJO">
                                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="currentcolor" data-testid="phone-icon" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Smartphone__SvgSmartphone-sc-2bgefj-0 kdWtyj">
                                        <path d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z" fill-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                    <input data-testid="form-input" id="form-input" name="form-input" height="48px" placeholder="+1 (###) ###-####" font-size="2,,1" class="sc-tagGq fHqGUr" value="" />
                                  </div>
                                </div>
                                <div class="sc-aXZVg OptInForm__ButtonBox-sc-8bbmi7-0 leRkyE bKbHxa">
                                  <button data-testid="form-button" class="sc-dhKdcB lmgKyB OptInForm__SignUpButton-sc-8bbmi7-2 hkUFKU" color="primary">
                                    <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">Send Link</div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sc-aXZVg sc-hmdomO ldwMXy jgwuHU">
                        <div class="sc-aXZVg sc-eqUAAy dUQmGt heAqDO">
                          <div class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                            <div width="104px" class="sc-aXZVg eWQxRd">
                              <img alt="app screenshot" height="100%" src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/app-download/opt-in-app-download-square.png?opto&amp;auto=avif,webp" data-testid="mobile-image" width="104px" loading="lazy" class="sc-kdBSHD OptInCard__OptInImage-sc-ji1nfw-1 pSaee evIfdX" />
                            </div>
                            <div class="sc-aXZVg sc-eqUAAy ldwMXy evpJHe">
                              <div class="sc-aXZVg ldwMXy">
                              <div color="text" font-weight="700" font-size="20px" class="sc-fqkvVR cSOqYT">Our Best Deals are in the App</div>
                              <div color="text" font-weight="500" font-size="14px" letter-spacing="-0.003rem" class="sc-fqkvVR eqPnni">Discover hotel, flight and rental car deals exclusively in the app. Download today to stay connected with important trip details.</div>
                              </div>
                            </div>
                          </div>
                          <div width="1" class="sc-aXZVg feckNt">
                            <div class="sc-aXZVg ldwMXy">
                              <div width="1" class="sc-aXZVg sc-eqUAAy eGwsri kmNFHk">
                                <div width="1" class="sc-aXZVg iyjFLz">
                                  <div class="sc-aXZVg sc-eqUAAy ldwMXy evpJHe">
                                    <div data-testid="icon-field-prefix-icons" class="sc-aXZVg sc-eqUAAy czUyOF hIDAJO">
                                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="currentcolor" data-testid="phone-icon" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Smartphone__SvgSmartphone-sc-2bgefj-0 kdWtyj">
                                        <path d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z" fill-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                    <input data-testid="form-input" id="form-input" name="form-input" height="48px" placeholder="+1 (###) ###-####" font-size="2,,1" class="sc-tagGq fHqGUr" value="" />
                                  </div>
                                </div>
                                <div class="sc-aXZVg OptInForm__ButtonBox-sc-8bbmi7-0 leRkyE bKbHxa">
                                  <button data-testid="form-button" class="sc-dhKdcB lmgKyB OptInForm__SignUpButton-sc-8bbmi7-2 hkUFKU" color="primary">
                                    <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">Send Link</div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{gap:'16px'}} class="sc-aXZVg sc-eqUAAy ldwMXy heAqDO">
                    <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                      <div class="sc-aXZVg ldwMXy">
                        <div color="primary.light" height="72" width="72" class="sc-aXZVg sc-eqUAAy hyoFtz cgaCzu">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="32" width="32" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 jHlNZc Bag__SvgBag-sc-1hqc6cc-0 egaGcs">
                            <path d="M20 6.5h-3v-2c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-11c0-1.1-.9-2-2-2zm-11-2h6v2H9v-2zm11 15H4v-2h16v2zm0-5H4v-6h3v2h2v-2h6v2h2v-2h3v6z"></path>
                          </svg>
                        </div>
                      </div>
                      <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                        <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                          <div font-weight="700" width="300px" font-size="14px" class="sc-fqkvVR sc-fUBkdm krFFQm ccmNyz">My Trips</div>
                          <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">Quickly find your booking reservations and redeem flight credits</div>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                        <title>ArrowRight</title>
                        <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                      </svg>
                    </div>
                    <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                      <div class="sc-aXZVg ldwMXy">
                        <div color="primary.light" height="72" width="72" class="sc-aXZVg sc-eqUAAy hyoFtz cgaCzu">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="32" width="32" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 jHlNZc Help__SvgHelp-sc-saxfvn-0 brWoWZ">
                            <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm1 17h-2v-2h2v2zm2.1-7.8l-.9.9c-.8.8-1.2 1.4-1.2 2.9h-2v-.5c0-1.1.4-2.1 1.2-2.8l1.2-1.3c.4-.3.6-.8.6-1.4 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.2 1.8-4 4-4s4 1.8 4 4c0 .9-.4 1.7-.9 2.2z"></path>
                          </svg>
                        </div>
                      </div>
                      <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                        <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                          <div font-weight="700" width="300px" font-size="14px" class="sc-fqkvVR sc-fUBkdm krFFQm ccmNyz">Help Center</div>
                          <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">We are always Here for you - reach us 24hrs a day, 7 days a week</div>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                        <title>ArrowRight</title>
                        <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg sc-feUZmu dVjqBv cxiIfM">
                  <div data-testid="merch-module-box" color="background.light" class="sc-aXZVg jYjIPn">
                    <div data-testid="merch-module-wrapper" width="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ModuleWrapper-sc-14j1nf9-0 fLxUHz BmYQI dHoagx">
                      <div data-testid="layout-flex" width="100%" class="sc-gEvEer sc-eqUAAy cdpMj bSzpSI">
                        <div width="1,,0.5" data-testid="box-0" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                          <div data-testid="merch-module-text-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy eQsLnH fiYCwu">
                            <div color="text.light" font-weight="700" font-size="12px" letter-spacing="0.063rem" class="sc-kAyceB isqCVK">LOVE AT FIRST SAIL</div>
                            <div color="text.base" font-weight="700" font-size="40px" class="sc-kAyceB dYbpGs">Get up to $1,000 cruise cash</div>
                            <p color="text.dark" font-size="16px" letter-spacing="-0.006rem" font-weight="500" class="sc-jXbUNg hPTigh">Spend it on spa treatments, specialty dinners, or romantic excursions. Plus, sail for the lowest price — only with us.</p>
                            <div class="sc-gEvEer sc-eqUAAy kqfIgw cabrXW">
                              <a href="https://cruises.priceline.com/?utm_medium=partner_site&amp;utm_source=partner_site_banner&amp;utm_campaign=20240129&amp;utm_content=promobox_valentines" target="_blank" rel="noopener" aria-label="Get up to $1,000 to spend on board " color="primary" class="sc-dcJsrY OjPuL">Plan My Perfect Cruise</a>
                            </div>
                          </div>
                        </div>
                        <div width="1,,0.5" data-testid="box-1" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                          <div data-testid="image-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ImageWrapper-sc-14j1nf9-2 ljuAJw cgaCzu bcqRBO">
                            <a aria-label="Get up to $1,000 to spend on board " data-testid="image-link-id" href="https://cruises.priceline.com/?utm_medium=partner_site&amp;utm_source=partner_site_banner&amp;utm_campaign=20240129&amp;utm_content=promobox_valentines" rel="noopener" target="_blank" color="primary" class="sc-dcJsrY eewULi">
                              <img alt="" width="100%" loading="lazy" src="//images.ctfassets.net/sdx4pteldsvw/2kv2E8m3ZgN4CS1a5KgOJB/ca800b5a0adc3519fedb94868931709c/V_Dy_Cruise.jpg?fm=webp&amp;q=80" class="sc-iGgWBj MerchImage__ResponsiveImage-sc-16ti2t2-0 dTIYZG eUjGEU" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg sc-eqUAAy ldwMXy NtmuT">
                  <div width="1,,0.6" class="sc-aXZVg WYJJs">
                    <div class="sc-aXZVg sc-kOHTFB sc-eNSrOW izsYur fDkWMd bjPSPu">
                      <div class="sc-aXZVg sc-gEvEer sc-kFWlue fleGre lrsvS ioVxDK">
                        <div class="sc-fqkvVR sc-dxcDKg czdWUD bdbgij sc-jOnpCo iwztGw">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 168 168" focusable="false" aria-hidden="true" role="img">
                            <circle cx="1100" cy="359" r="84" fill="#EDFF7A" fill-rule="evenodd" transform="translate(-1016 -275)"></circle>
                          </svg>
                        </div>
                      </div>
                      <div width="291.04px,,,400px,450px,551.25px" transform="scaleX(-1)" class="sc-aXZVg sc-gEvEer sc-bkEOxz ewbuYT kHlnRS ePnAMN">
                        <div class="sc-fqkvVR sc-dNsVcS czdWUD bFaeAz sc-fsvrbR jheWmd">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 412 293" focusable="false" aria-hidden="true" role="img">
                            <path fill="#0068ef" fill-rule="evenodd" d="M348.674 149.182c0-16.118-6.943-32.753-24.252-45.37-16.708-12.663-42.704-18.354-70.939-18.354-38.073 0-61.748 9.775-74.426 21.212-10.974 10.34-17.309 25.28-17.309 39.646 0 43.03 46.728 61.989 94.055 61.989 39.355 0 81.015-10.672 90.855-46.002l.288-1.079c1.054-3.808 1.662-7.608 1.723-11.409l.005-.633zm55.32-70.465h-28.236l1.382 1.142c2.986 2.474 5.906 4.958 8.406 7.436C399.99 102.243 412 129.234 412 162.58 412 236.104 356.16 293 255.888 293 194.263 293 98.53 265.954 98.53 163.121c0-25.633 6.49-41.752 16.059-58.58l.752-1.313.764-1.317.775-1.321.392-.664.793-1.331 1.21-2.012 1.647-2.716L123.897 89H0V0h403.994v78.717z"></path>
                          </svg>
                        </div>
                      </div>
                      <div width="90%,,,,100%,748px" height="175px,,374px" class="sc-aXZVg sc-gEvEer sc-dhFUGM hTpUvt iYRbFM csXEeD">
                        <div width="100%" height="100%" class="sc-aXZVg sc-eqUAAy sc-isRoRg kjpQAf jAekbq isVdPO">
                          <div width="100%" height="100%" class="sc-imWYAI sc-satoz dKRUzS froqZr"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div width="1,,0.4" class="sc-aXZVg eEdKXU">
                    <div color="text.heading" font-size="26px,,,32px,40px" font-weight="700,,,700,700" letter-spacing="," class="sc-fqkvVR fwXoUo">Priceline Experiences</div>
                    <div color="text.light" font-size="16px,,,20px" font-weight="500,,,500" letter-spacing="," class="sc-fqkvVR gjNkko">Access the most memorable experiences across the globe! Book over 80,000 travel activities in more than 100 countries worldwide!</div>
                    <a target="_blank" href="https://experiences.priceline.com/us?cid=hpgbanner&amp;aid=priceline" color="primary" rel="noopener" class="sc-jlZhew fTsEh">Explore Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg ldwMXy">
                  <div color="background.lightest" width="1" class="sc-aXZVg heCIPL">
                    <form aria-label="email-sign-up-form"><div width="1" class="sc-aXZVg sc-eqUAAy jaGgOB goTqVw">
                      <div class="sc-aXZVg sc-kgOKUu kYzkho fzKYiA">
                        <div color="text.base" font-weight="bold" font-size="3" class="sc-fqkvVR sc-bfhvDw eGtAPT iysLZV">
                          <p class="sc-iGgWBj ghQOGT sc-ce62b8ce-0 iMWoqA">Sign up for Exclusive Email-only Coupons</p>
                        </div>
                        <div color="text.light" font-size="2" class="sc-fqkvVR sc-bfhvDw hFMhwq iysLZV">
                          <p class="sc-iGgWBj ghQOGT sc-ce62b8ce-0 iMWoqA">Exclusive access to coupons, special offers and promotions.</p>
                        </div>
                      </div>
                      <div width="1,,,auto" class="sc-aXZVg sc-eqUAAy bOvDWU pvirm">
                        <div width="1" class="sc-aXZVg sc-eqUAAy feckNt juUPFK">
                          <div width="1" class="sc-aXZVg sc-eqUAAy feckNt laIosI">
                            <div width="296,,,200,400" class="sc-aXZVg dSdBAN">
                              <div class="sc-aXZVg sc-dtBdUo fPsAVt hHvdph">
                                <div class="sc-aXZVg sc-eqUAAy ldwMXy evpJHe">
                                  <input aria-label="Promotion email input" id="emailAddress-1706865475041" name="emailAddress" placeholder="example@example.com" 
                                    style={{transitionProperty:'padding-top, padding-bottom', transitionDuration:'1s'}} class="sc-tagGq hJSqeZ sc-iLsKjm bIiIar" font-size="2,,1" />
                                </div>
                              </div>
                            </div>
                            <div width="1,,,,148" class="sc-aXZVg kfShAY">
                              <button color="primary" type="submit" width="100%" class="sc-dhKdcB gzPrHe sc-eifrsQ hVaSbQ">
                                <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">send me deals</div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg ldwMXy">
                  <h3 color="text.heading" font-weight="700" font-size="32px" class="sc-fqkvVR jUVzqw">Discover deals in every city</h3>
                  <div class="sc-aXZVg sc-eqUAAy fZIqsM eCdPAh">
                    <div width="1,1,0.5,0.5,0.6666666666666666" class="sc-aXZVg kSKTWB">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/new-york-city.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI bnQmMX ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">24-HOUR EXCITEMENT</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" className='my-0'>
                              <p className='my-0' style={{fontFamily:'"French Script MT"', fontSize:'52px'}}>New York</p></div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000016152/US/New-York/New-York-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/new-york.15830.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/New-York-NYC.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/los-angeles.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI NHEcp ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">CUTTING-EDGE COOL</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div class="la-svg-wrapper">
                            <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" style={{fontFamily:'"Imprint MT Shadow"', fontSize:'36px'}}>LOS ANGELES</div>
                            </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000001947/US/California/Los-Angeles-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/los-angeles.16078.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/Los-Angeles-LAX.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/orlando.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI cZOIYG ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">THEME PARK PARADISE</div><div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                              <b style={{fontFamily:'cursive', fontSize:'32px'}}>ORLANDO</b>
                              </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000003349/US/Florida/Orlando-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/orlando.9900.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/Orlando-ORL.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/atlanta.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI sykmU ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">SWEET SOUTHERN CHARM</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'fantasy', fontSize:'42px', fontWeight:'200'}}>Atlanta</b>
                              </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000003496/US/Georgia/Atlanta-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/atlanta.11123.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/Atlanta-Hartsfield-Jackson-ATL.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/dallas.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI lncQFE ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">FOOD CAPITAL</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <p className='my-0' style={{fontFamily:'"Colonna MT"', fontSize:'38px'}}>DALLAS</p>
                              </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000021082/US/Texas/Dallas-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/dallas.16406.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/Dallas-A78.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/san-francisco.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI cXDWsA ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">SOAK UP THE SCENES</div><div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div class="sf-svg-wrapper">
                            <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <p style={{fontFamily:'"Bodoni MT Black"', fontSize:'38px'}}>San Francisco</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000002244/US/California/San-Francisco-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/san-francisco.13852.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/San-Francisco-SFO.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.6666666666666666" class="sc-aXZVg kSKTWB">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/miami.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI ldSBtq ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">IT'S BEACH TIME</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'Copperplate, Papyrus, fantasy', fontSize:'46px'}}>Miami</b>
                              </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000003311/US/Florida/Miami-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/miami.14305.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/Miami-MIA.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/denver.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI gWSbkB ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">MILE-HIGH MAGIC</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'Times-new-roman', fontSize:'46px'}}>Denver</b>
                              </div>
                           </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000002573/US/Colorado/Denver-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/denver.12493.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/Denver-Intl-DEN.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/seattle.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI iKguPp ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">PACIFIC NORTHWEST BEAUTY</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                            <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'monospace', fontSize:'42px'}}>Seattle</b>
                              </div>
                             </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000023414/US/Washington/Seattle-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/seattle.7054.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/Seattle-Tacoma-Intl-SEA.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/san-diego.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI iwlNAu ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">RIDE THE WAVES</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                            <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'"Mistral"', fontSize:'42px'}}>SAN DIEGO</b>
                              </div>
                            </div>
                         </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/hotel-deals/c3000002241/US/California/San-Diego-Hotels.html" target="_blank" data-testid="imageTile-nav-link" id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/rental-car-deals/san-diego.10760.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Cars</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          <div class="sc-aXZVg ldwMXy">
                            <a href="/flight-routes/San-Diego-SAN.ksp" target="_blank" data-testid="imageTile-nav-link" id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg sc-feUZmu dVjqBv cxiIfM">
                  <div data-testid="merch-module-box" color="background.light" class="sc-aXZVg jYjIPn">
                    <div data-testid="merch-module-wrapper" width="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ModuleWrapper-sc-14j1nf9-0 fLxUHz BmYQI dHoagx">
                      <div data-testid="layout-flex" width="100%" class="sc-gEvEer sc-eqUAAy cdpMj bSzpSI">
                        <div width="1,,0.5" data-testid="box-0" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                          <div data-testid="merch-module-text-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy eQsLnH fiYCwu">
                            <div color="text.light" font-weight="700" font-size="12px" letter-spacing="0.063rem" class="sc-kAyceB isqCVK">WALT DISNEY WORLD VACATIONS</div>
                            <div color="text.base" font-weight="700" font-size="40px" class="sc-kAyceB dYbpGs">Stay in the Magic and save!</div>
                            <p color="text.dark" font-size="16px" letter-spacing="-0.006rem" font-weight="500" class="sc-jXbUNg hPTigh">Save up to 35% on rooms at select Disney Resort hotels when you stay 5 nights or more – or save up to 30% on shorter stays most nights when you travel from January 9 – October 3, 2024.</p>
                            <div class="sc-gEvEer sc-eqUAAy kqfIgw cabrXW">
                              <a href="https://www.priceline.com/partner/v2/walt-disney-world" target="_blank" rel="noopener" aria-label="Walt Disney World Promo Link" color="primary" class="sc-dcJsrY OjPuL">More Info</a>
                            </div>
                          </div>
                        </div>
                        <div width="1,,0.5" data-testid="box-1" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                          <div data-testid="image-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ImageWrapper-sc-14j1nf9-2 ljuAJw cgaCzu bcqRBO">
                            <a aria-label="Walt Disney World Promo Link" data-testid="image-link-id" href="https://www.priceline.com/partner/v2/walt-disney-world" rel="noopener" target="_blank" color="primary" class="sc-dcJsrY eewULi">
                              <img alt="Save on Disney Resort hotels" width="100%" loading="lazy" src="//images.ctfassets.net/sdx4pteldsvw/4eYxnp5gaDTeUpEjPr7SZo/66c647b96ffcbaf0ee3e0cb9f7014a3c/WDW-Marketing-Tout.jpg?fm=webp&amp;q=80" class="sc-iGgWBj MerchImage__ResponsiveImage-sc-16ti2t2-0 bkRuHn eimqwJ" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sc-aXZVg ldwMXy">
              <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
                <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                  <div class="sc-aXZVg sc-feUZmu dVjqBv cxiIfM">
                    <div data-testid="merch-module-box" color="background.light" class="sc-aXZVg jYjIPn">
                      <div data-testid="merch-module-wrapper" width="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ModuleWrapper-sc-14j1nf9-0 fLxUHz BmYQI dHoagx">
                        <div data-testid="layout-flex" width="100%" class="sc-gEvEer sc-eqUAAy cdpMj bSzpSI">
                          <div width="1,,0.5" data-testid="box-0" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                            <div data-testid="merch-module-text-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy eQsLnH fiYCwu">
                              <div color="text.light" font-weight="700" font-size="12px" letter-spacing="0.063rem" class="sc-kAyceB isqCVK">SPECIAL NEW YEAR'S OFFER</div>
                              <div color="text.base" font-weight="700" font-size="40px" class="sc-kAyceB dYbpGs">The All-In-One Travel Card</div>
                              <p color="text.dark" font-size="16px" letter-spacing="-0.006rem" font-weight="500" class="sc-jXbUNg hPTigh">
                                Earn a $100 statement credit + 5K bonus points after qualifying purchases with the Priceline VIP Rewards™ Visa® Card. Plus, earn 5X on Priceline bookings, 2X on restaurant and gas purchases, and automatic VIP Gold Status! Terms apply.
                              </p>
                              <div class="sc-gEvEer sc-eqUAAy kqfIgw cabrXW">
                                <a href="https://cards.barclaycardus.com/banking/credit-card/priceline/visa-signature/banner/36c96d65-79d0-426a-85f3-9d295d7af24f?referrerid=HomepageSOS23" target="_blank" rel="noopener" aria-label="homepage credit card merch module " color="primary" class="sc-dcJsrY OjPuL">Learn More</a>
                              </div>
                            </div>
                          </div>
                          <div width="1,,0.5" data-testid="box-1" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                            <div data-testid="image-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ImageWrapper-sc-14j1nf9-2 ljuAJw cgaCzu bcqRBO">
                              <a aria-label="homepage credit card merch module " data-testid="image-link-id" href="https://cards.barclaycardus.com/banking/credit-card/priceline/visa-signature/banner/36c96d65-79d0-426a-85f3-9d295d7af24f?referrerid=HomepageSOS23" rel="noopener" target="_blank" color="primary" class="sc-dcJsrY eewULi">
                                <img alt="" width="100%" loading="lazy" src="//images.ctfassets.net/sdx4pteldsvw/7cZWw4Z5vHwFUQjrUBQJt/5299b0167153218b03556607b28b5018/CreditCardLTO_Tout_600x480__5_.jpg?fm=webp&amp;q=80" class="sc-iGgWBj MerchImage__ResponsiveImage-sc-16ti2t2-0 bkRuHn eimqwJ" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                  <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy frsMYR eCdPAh">
                    <div width="0.5,,,,0.25" data-testid="box-0" class="sc-aXZVg sc-JrDLc knvObg csbhqS">
                      <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jFwuXl cZAEdd sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="" tabindex="-1">
                        <div class="sc-aXZVg ldwMXy">
                          <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 gHvJpT Verified__SvgVerified-sc-a72ym8-0 eHnFep">
                              <path d="M12 2L4 5.6v5.5c0 5 3.4 9.8 8 10.9 4.6-1.1 8-5.9 8-10.9V5.6L12 2zm-1.8 14.5l-3.6-3.6 1.3-1.3 2.3 2.3 5.9-6 1.3 1.3-7.2 7.3z"></path>
                            </svg>
                          </div>
                        </div>
                        <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                          <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                            <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">Flexible Bookings</div>
                            <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">Plans change. That’s why we offer free cancellation on most hotels &amp; rental cars.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div width="0.5,,,,0.25" data-testid="box-1" class="sc-aXZVg sc-JrDLc knvObg csbhqS">
                      <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jFwuXl cZAEdd sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="" tabindex="-1">
                        <div class="sc-aXZVg ldwMXy">
                          <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 gHvJpT Cars__SvgCars-sc-1ky1id6-0 bucAKE">
                              <path d="M18.9 5c-.2-.6-.8-1-1.4-1h-11c-.7 0-1.2.4-1.4 1L3 11v8c0 .6.4 1 1 1h1c.5 0 1-.4 1-1v-1h12v1c0 .6.4 1 1 1h1c.5 0 1-.4 1-1v-8l-2.1-6zM6.5 15c-.8 0-1.5-.7-1.5-1.5S5.7 12 6.5 12s1.5.7 1.5 1.5S7.3 15 6.5 15zm11 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM5 10l1.5-4.5h11L19 10H5z"></path>
                            </svg>
                          </div>
                        </div>
                        <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                          <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                            <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">No Flight? No Problem</div>
                            <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">Bundle hotel &amp; rental car deals to build your perfect getaway. No airtime required.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div width="0.5,,,,0.25" data-testid="box-2" class="sc-aXZVg sc-JrDLc knvObg csbhqS">
                      <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                        <div class="sc-aXZVg ldwMXy">
                          <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 gHvJpT Discount__SvgDiscount-sc-1s5abed-0 fkoOZw">
                              <path d="M23 12l-2.4 2.7.3 3.5-3.6.8-1.9 3-3.4-1.4L8.6 22l-1.9-3-3.6-.8.3-3.5L1 12l2.4-2.7-.3-3.5L6.7 5l1.9-3L12 3.4 15.4 2l1.9 3 3.6.8-.3 3.5L23 12zm-10.8-.6c-1.3-.3-1.8-.7-1.8-1.3 0-.6.6-1.1 1.6-1.1s1.4.5 1.5 1.2h1.3c0-1-.7-1.9-1.9-2.2V6.7h-1.8V8c-1.1.2-2 1-2 2.1 0 1.3 1.1 2 2.8 2.4 1.5.4 1.8.9 1.8 1.4 0 .4-.3 1-1.6 1-1.2 0-1.7-.5-1.8-1.2H9c.1 1.3 1 2 2.2 2.2v1.3H13V16c1.1-.2 2.1-.9 2.1-2.1-.1-1.6-1.5-2.2-2.9-2.5z"></path>
                            </svg>
                          </div>
                        </div>
                        <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                          <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                            <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">Incredible Deals</div>
                            <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">Check out with confidence. Priceline members always get our best price.</div>
                          </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                          <title>ArrowRight</title>
                          <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                        </svg>
                      </div>
                    </div>
                    <div width="0.5,,,,0.25" data-testid="box-3" class="sc-aXZVg sc-JrDLc knvObg csbhqS">
                      <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                        <div class="sc-aXZVg ldwMXy">
                          <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 gHvJpT Help__SvgHelp-sc-saxfvn-0 brWoWZ">
                              <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm1 17h-2v-2h2v2zm2.1-7.8l-.9.9c-.8.8-1.2 1.4-1.2 2.9h-2v-.5c0-1.1.4-2.1 1.2-2.8l1.2-1.3c.4-.3.6-.8.6-1.4 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.2 1.8-4 4-4s4 1.8 4 4c0 .9-.4 1.7-.9 2.2z"></path>
                            </svg>
                          </div>
                        </div>
                        <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                          <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                            <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">Help 24/7</div>
                            <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">We’re always here for you – reach us 24 hours a day, 7 days a week.</div>
                          </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                          <title>ArrowRight</title>
                          <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/* <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
      <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
        <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
          <div class="sc-aXZVg sc-feUZmu iHVsUp cxiIfM">
            <div id="div-gpt-ad-1614716188275-0" data-google-query-id="CJWP2PanjIQDFUNBHQkdIdAOtg">
              <div id="google_ads_iframe_/3102/priceline.dart/main_homepage_728x90_0__container__" style={{border: '0pt none'}}>
                <iframe id="google_ads_iframe_/3102/priceline.dart/main_homepage_728x90_0" name="google_ads_iframe_/3102/priceline.dart/main_homepage_728x90_0" title="3rd party ad content" width="728" height="90" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" aria-label="Advertisement" tabindex="0" allow="attribution-reporting" style={{border: '0px', verticalAlign: 'bottom'}} data-load-complete="true" data-google-container-id="1" data-integralas-id-6ec9b453-23c0-102d-fe20-765938faf54d="">www.priceline.com</iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </div>
    </>
  )
}

export default OtherComponents
