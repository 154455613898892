import React, { useState ,useEffect,useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faSearch,
  faArrowRight,
  faAngleDown,
  faBowlFood,
  faFilter,
  faDollar
} from '@fortawesome/free-solid-svg-icons'
import bgimage from '../../Images/Hotels/bg.jpg'
import noUiSlider from 'nouislider'
import { RangeSlider } from 'rsuite';
import HotelCard from '../../Components/HotelCard/Hotelcard'
import { useSelector } from 'react-redux';
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import HotelSearch from '../../Components/SearchBar/HotelSearch';
import Layout from '../../Components/Layout/Layout'
import moment from 'moment';
function Hotels () {
  const hotels = useSelector((state) => state.hotels.hotels);
  const hotelsSearchData = useSelector((state) => state.hotels.hsearch);
  const minamounts = hotels.hotels_list.map(hotel => Number(hotel.min_price));
  const minValue2 = Math.min(...minamounts);
  const maxValue2 = Math.max(...minamounts);
  const [minValue, setMinValue] = useState(minValue2);
  const [maxValue, setMaxValue] = useState(maxValue2);
  const [hotelNameFilter, setHotelNameFilter] = useState(null);
  const [baseCName, setBaseCName] = useState('GBP')
  const [rangeValue, setRangeValue] = useState([minValue2, maxValue2]);
  const [ShowPriceFilter, setShowPriceFilter] = useState(false);
  const [ShowMealTYpeFilter, setShowMealTYpeFilter] = useState(false);
  const [ShowRatingFilter, setShowRatingFilter] = useState(false);
  const [ShowFacilityFilter, setShowFacilityFilter] = useState(false);
  const [showModifySearch, setShowModifySearch] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState([])
  const [hotelid, setHotelid] = useState("");
  const [showfilter, setShowfilter] = useState({rating:true,meal:true,facility:true,name:true,price:true});
  const [starRating, setStarRating] = useState({rating5:'0',rating4:'0',rating3:'0',rating2:'0',rating1:'0',type:'Stars'});
  const [mealRating, setMealRating] = useState({meal1:'',meal2:'',meal3:'',meal4:'',type:'meal'});
  const [selectedFacilities, setSelectedFacilities] = useState({ type: 'facility' }); // Initialize an empty array for selected items
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    setStarRating((prevDetails) => {
      const updatedStarRating = { ...prevDetails };
      if (updatedStarRating[name] !=="0") {
        updatedStarRating[name]="0";
      } else {
        updatedStarRating[name] =value;
      }
  
      return updatedStarRating;
    });
  };
  const handleMealTypeChange = (event) => {
    const { name, value } = event.target;

    setMealRating((prevDetails) => {
      const updatedStarRating = { ...prevDetails };
      if (updatedStarRating[name] !=="") {
        updatedStarRating[name]="";
      } else {
        updatedStarRating[name] =value;
      }
  
      return updatedStarRating;
    });
  };

  const handleFacilityChange = (event) => {
    const { name, value } = event.target;
    const isSelected = selectedFacilities[name] === value;
    
    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...selectedFacilities };
      delete updatedFacilities[name];
      setSelectedFacilities(updatedFacilities);
    } else {
      // If not selected, add it to the object
      setSelectedFacilities({ ...selectedFacilities, [name]: value });
    }

  };
  useEffect(() => {
  }, [hotelsSearchData,hotels])
  const sliderRef = useRef(null);

  useEffect(() => {
    console.log("minValue2:", minValue2, "maxValue2:", maxValue2);
    const slider = sliderRef.current;
    if (slider && isFinite(minValue2) && isFinite(maxValue2)) {
      noUiSlider.create(slider, {
        start: [minValue, maxValue],
        connect: true,
        range: {
          min: parseFloat(minValue2),
          max: parseFloat(maxValue2),
        },
      });
  
      slider.noUiSlider.on('update', (values, handle) => {
        if (handle === 0) {
          setMinValue(parseInt(values[handle]));
        } else {
          setMaxValue(parseInt(values[handle]));
        }
      });
  
      return () => {
        slider.noUiSlider.destroy();
      };
    } else {
      console.error("Invalid min or max value. Please check the source data.");
    }
  }, [minValue, maxValue, minValue2, maxValue2]);

  const handleHotelFilter = inputValue => {
    setHotelid(inputValue.value);
    setHotelNameFilter(inputValue);
  };
  const StarFilter=()=>{
    setShowRatingFilter(false);
    setHotelid(starRating);
  };
const PriceFilter=()=>{
  setShowPriceFilter(false);
  var data={type:'price',min:minValue,max:maxValue};
  setHotelid(data);
}
  const MealFilter=()=>{
    setShowMealTYpeFilter(false);
    setHotelid(mealRating);
  };

  const FacilityFilter=()=>{
    setShowFacilityFilter(false);
    setHotelid(selectedFacilities);
  };
  const DisplayModifySearch=()=>{
    setShowModifySearch(!showModifySearch);
  };
  const Showfilter=(num)=>{
    if(num===1){
      setShowfilter(prevData => ({ ...prevData, rating: !prevData.rating }));
    }
    if(num===2){
      setShowfilter(prevData => ({ ...prevData, meal: !prevData.meal }));
    }
    if(num===3){
      setShowfilter(prevData => ({ ...prevData, facility: !prevData.facility }));
    }
    if(num===6){
      setShowfilter(prevData => ({ ...prevData, price: !prevData.price }));
    }
    if(num===7){
      setShowfilter(prevData => ({ ...prevData, name: !prevData.name }));
    }
  };

  const TogglePriceFilter=()=>{
    setShowPriceFilter(!ShowPriceFilter);
  };
  const ToggleMealTypeFilter=()=>{
    setShowMealTYpeFilter(!ShowMealTYpeFilter);
  };
  const ToggleRatingFilter=()=>{
    setShowRatingFilter(!ShowRatingFilter);
  };
  const ToggleFacilityFilter=()=>{
    setShowFacilityFilter(!ShowFacilityFilter);
  };
  const handleChange = (newRangeValue) => {
    const newMinValue = parseFloat(newRangeValue[0]);
    const newMaxValue = parseFloat(newRangeValue[1]);
    setRangeValue([newMinValue, newMaxValue]);
    setMinValue(newMinValue);
    setMaxValue(newMaxValue);
  };
  return (
    <>
        <Modal isOpen={ShowPriceFilter} toggle={TogglePriceFilter}>
          <ModalHeader toggle={TogglePriceFilter}>Price Filter</ModalHeader>
          <ModalBody>
             <div className='widget widget_price_filter'>
                <div className='mb-0'>
                   <h3 className='form-label'>Price Level</h3> 
                  <RangeSlider
                      value={rangeValue}
                      onChange={handleChange}
                      min={minValue2}
                      tooltip={false}
                      max={maxValue2}
                      step={1}
                    />                  
                  <div className='pt-2'>
                    <div className='fw-bold mb-2'>
                      Min: <span id='kt_slider_basic_min'>{minValue.toFixed(0)} </span>
                    </div>
                    <div className='fw-bold mb-2'>
                      Max: <span id='kt_slider_basic_max'>{maxValue.toFixed(0)}</span>
                    </div>
                  </div>
                  <button onClick={PriceFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowMealTYpeFilter} toggle={ToggleMealTypeFilter}>
          <ModalHeader toggle={ToggleMealTypeFilter}>Meal Type Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' >                
                {/* <h3>Meal Type</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal1' value='ROOM ONLY' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>  Room Only</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal2' value='BED AND BREAKFAST' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> BED AND BREAKFAST </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal3' value='HALF BOARD' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> HALF BOARD</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal4' value='Full BOARD' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Full BOARD</span>
                    </label>
                  </li>
                </ul>
                <button onClick={MealFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
          <ModalHeader toggle={ToggleRatingFilter}>Star Rating Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' onClick={()=>Showfilter(1)}>                
                  {/* <h3>Filter by Rating</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' 
                      className='custom-textbox' 
                      onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button onClick={StarFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
                 
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowFacilityFilter} toggle={ToggleFacilityFilter}>
          <ModalHeader toggle={ToggleFacilityFilter}>Facility Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' onClick={()=>Showfilter(3)}>                
                {/* <h3>Facilities</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul> 
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility1' value='Wi-fi' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wi-fi</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility2' value='Internet access' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Internet access </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility3' value='TV' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> TV</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Wake-up service' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wake-up service</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Smoking rooms' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Smoking rooms</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility6' value='Wheelchair-accessible' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wheelchair-accessible</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'  className='custom-textbox' onChange={handleFacilityChange} name='facility7' value='Laundry service' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Laundry service</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility8' value='Banquet hall' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Banquet hall</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility9' value='Non-smoking establishment' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Non-smoking establishment</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility10' value='Safe' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Safe</span>
                    </label>
                  </li>
                </ul>
                <div>
                <button onClick={FacilityFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
                </div>
              
              </div>
          </ModalBody>
        </Modal>
    <Layout>
      <div className='container-fluid p-0'>
        {/* <div className='mt-2'>
          <div style={{cursor:'pointer'}} className='d-flex mt-3 flight-modify-responsive p-2 hotel-top' onClick={DisplayModifySearch}>
            <div className="d-flex align-items-center flight-new-search">
              <FontAwesomeIcon icon={faSearch}/>
            </div>
            <div  className="d-flex align-items-center w-100 justify-content-between">
              <div className=' ms-2'>
                <h5 className='title font-size-24 tc' id='tours_result'>
                  {hotelsSearchData.destination_name} - {hotelsSearchData.country}
                </h5>
                <h6 className='title font-size-24 tc' id='tours_result'>
                {hotelsSearchData.adult !== 0 && (
                  <>
                    {hotelsSearchData.adult} adult {' '}
                  </>
                )}
                {hotelsSearchData.child !== 0 && (
                  <>
                    - {hotelsSearchData.child} child
                  </>
                )}
                
                </h6>
              </div>
               <h6 className='title font-size-24 tc' id='tours_result'>
                      {moment(hotelsSearchData.check_in).format('MMM Do ')}{' '}
                      <FontAwesomeIcon icon={faArrowRight} />{' '}
                      {moment(hotelsSearchData.check_out).format('MMM Do ')}      
                  </h6> 
            </div>
          </div>
          {showModifySearch &&(
            )}
        </div> */}
          <div className="modify-flight-search hpuREe" style={{marginTop:'1px'}}> <HotelSearch/></div>
      </div>
      <div className='container-fluid'>
        <div className='row mt-2 pb-5'>
          <div className='col-md-3 col-md-pull-9 px-4'>
            <div class="mobile-Filter-info mb-3">
              <ul>
                  <li><p onClick={TogglePriceFilter}><FontAwesomeIcon icon={faDollar}/> Price</p></li>
                  <li><p onClick={ToggleMealTypeFilter}><FontAwesomeIcon icon={faBowlFood}/><span > Meal Type</span></p></li>
                  <li><p onClick={ToggleRatingFilter}><FontAwesomeIcon icon={faStar}/><span > Rating</span></p></li>
                  <li><p onClick={ToggleFacilityFilter}><FontAwesomeIcon icon={faFilter}/><span > Facilities</span></p></li>
              </ul>
            </div>
            <div className='page-sidebar'>
              <div
                  id='map-container-google-2'
                  className='z-depth-1-half map-container rounded-5'
                  style={{ height: '500' }}
                >
                  <iframe
                    src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                  &q=Mecca+Saudi+Arabia'
                    frameborder='0'
                    allowfullscreen
                    className='rounded-4'
                  ></iframe>
              </div>
             <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide bwCBvh kuQtDu' onClick={()=>Showfilter(7)}>                
                  <h3 className='fMYYEI'>Property Name</h3>
                  <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i>
                </div>
                {showfilter.name && (
                  <div className='edRKIj llNdfK gCLia-d'>
                      <Select className='bzqoba Gzjpn'
                            value={hotelNameFilter}
                            onChange={handleHotelFilter}
                            options={hotels.hotels_list.map(option => ({
                              value: option.hotel_id,
                              label:option.hotel_name
                            }))}
                        />
                      <button className='HWMyp'>  
                        <i className='fa fa-star'>{' '}<FontAwesomeIcon icon={faSearch} /></i>
                      </button>
                  </div>
                  )}
              </div>
            </div>
            <div className='page-sidebar hide-page_filter'>
              <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide bwCBvh kuQtDu mt-2' onClick={()=>Showfilter(3)}>                
                  <h3 className='fMYYEI'>Facilities</h3>
                  <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i>
                </div>
                {showfilter.facility && (
                  <div className='edRKIj gCLia-d'>
                    <ul className='bzqoba '>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility1' value='Wi-fi' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Wi-fi</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility2' value='Internet access' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Internet access </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility3' value='TV' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> TV</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Wake-up service' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Wake-up service</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Smoking rooms' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Smoking rooms</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility6' value='Wheelchair-accessible' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Wheelchair-accessible</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox'  className='custom-textbox' onChange={handleFacilityChange} name='facility7' value='Laundry service' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Laundry service</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility8' value='Banquet hall' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Banquet hall</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility9' value='Non-smoking establishment' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Non-smoking establishment</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility10' value='Safe' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Safe</span>
                        </label>
                      </li>
                    </ul>
                    <button onClick={FacilityFilter} className='HWMyp w-100'>Apply</button>
                 </div>
                )}
              </div>
              <div className='widget widget_price_filter'>
                <div className='filter-show-hide bwCBvh kuQtDu mt-2' onClick={()=>Showfilter(6)}>                
                  <h3 className='fMYYEI'>Price Level</h3>
                  {/* <div ref={sliderRef} /> */}
                  <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i>
                </div>
                <div className='px-3 mt-4' ref={sliderRef} />
                {showfilter.price && (
                  <div className='edRKIj gCLia-d'>
                    <div className='fw-bold mb-2 bzqoba'>
                      Min: <span id='kt_slider_basic_min'>{minValue} </span>
                    </div>
                    <div className='fw-bold mb-2 bzqoba'>
                      Max: <span id='kt_slider_basic_max'>{maxValue} </span>
                    </div>
                    <button onClick={PriceFilter} className='HWMyp w-100'>Apply</button>
                 </div>
                )}
              </div>
              <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide bwCBvh kuQtDu mt-3' onClick={()=>Showfilter(1)}>                
                  <h3 className='fMYYEI'>Filter by Rating</h3>
                  <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i>
                </div>
                {showfilter.rating && (
                  <div className='edRKIj gCLia-d'>
                    <ul className='bzqoba '>
                      <li>
                        <label>
                          <input type='checkbox' 
                          className='custom-textbox' 
                          onChange={handleCheckboxChange} name='rating5' value='5' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>
                            <i className='fa fa-star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </i>
                          </span>
                        </label>
                      </li>
                    </ul>
                    <button onClick={StarFilter} className='HWMyp w-100'>Apply</button>
                  </div>
                  )}
              </div>
              <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide bwCBvh kuQtDu mt-2' onClick={()=>Showfilter(2)}>                
                  <h3 className='fMYYEI'>Meal Type</h3>
                  <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i>
                </div>
                {showfilter.meal &&(
                 <div className='edRKIj gCLia-d'>
                    <ul className='bzqoba'>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal1' value='ROOM ONLY' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>  Room Only</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal2' value='BED AND BREAKFAST' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> BED AND BREAKFAST </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal3' value='HALF BOARD' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> HALF BOARD</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal4' value='Full BOARD' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Full BOARD</span>
                        </label>
                      </li>
                    </ul>
                    <button onClick={MealFilter} className='HWMyp w-100'>Apply</button>
                </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-md-9 col-md-push-3'>
              <div className='mb-3'>
                <h6 className='title' style={{fontSize:'18px', color:'black'}} id='tours_result'>
                  {hotels.hotel_beds_counts} Hotels Available in  {hotelsSearchData.destination_name}, {hotelsSearchData.country}
                </h6>
              </div>
            <HotelCard hotelid={hotelid}/>
          </div>
        </div>
      </div>
      </Layout>
    </>
  )
}

export default Hotels
