import React, { useState, useEffect } from 'react'
import logo from '../../Images/Logo/logo.png'
import Axios from 'axios'
import ReactDOM from 'react-dom'
import { json, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faSignIn,
  faBars,
  faTimes,
  faChevronDown,
  faCross,
  faClose,
  faAngleRight,
  faArrowDown,
  faAngleDown,
  faMosque,
  faKaaba
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import LanguageSwitcher from '../Activity/TranslationTrigger';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next';
function Navbar () {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigation = useNavigate()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [modalOpen, setModalOpen] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [userCountry, setUserCountry] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownCat, setDropdownCat] = useState([])
  const [isDropdownOpen2, setDropdownOpen] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('GBP')
 
  // This function put query that helps to
  // change the language

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen2)
  }

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }
  const buttonIconClass = isMobileNavOpen
    ? 'navbar-toggler-icon-cross'
    : 'navbar-toggler-icon'
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  useEffect(() => {
    GetCatagories();
    fetchData();
    AllCurrency();
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
    }
  }, []);

  const SearchValue = e => {
    var filteredList = countryList.filter(item =>
      item.currency.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setUserCountry(filteredList)
    setSearchValue(e.target.value)
  };

  async function fetchData () {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = (index, currency) => {
    toggleModal();
    setActiveIndex(index);
    setSelectedCurrency(currency);
    CurrencyCalucaltion(currency);
    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c;
        dispatch(CurrencyRates(response.data));
        
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    };

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        dispatch(AllCurrencyRates(response.data))
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    };
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
        sessionStorage.setItem('Catogories',JSON.stringify(response.data.categories));
      setDropdownCat(response.data.categories);
     
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const FindPackageDetail = id => {
    toggleDropdown();
    navigation('/umrah_packages', { state: id })
  };
  const languages = [
    { value: "en", text: "English" },
    { value: "ar-SA", text: "Arabic" },
];

  return (
    <>
      <div id="exp-hdr" class="experimental-header">
        <div class="fixed" id="pcln-global-header">
          <div>
             <link rel="preconnect" href="https://assets.pclncdn.com/" crossorigin="anonymous" />
             <link rel="preconnect" href="https://s1.pclncdn.com/" crossorigin="anonymous" />
             <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" charset="UTF-8" data-domain-script="378ca1c8-45b1-4cb7-b131-b46f4752e78f"></script>
             <script>function OptanonWrapper() { }</script>
             <div id="hidden-sticky-sign-in" style={{display:'none'}}>
              <div id="signed-in">false</div>
             </div>
             <div id="global-promo-banner" class="global-promo-banner" style={{backgroundColor:'#0068EF', color:'#FFFFFF'}} data-gacategory="promotion" data-galabel="HOMEPAGE:valentines-bliss-sale-offer-CLICK" data-gaaction="weekly-2024">
              <div class="global-promo-banner-wrapper">  
                <a aria-label="Valentine's Bliss: Create cherished memories and save as much as $250 extra when you bundle. Terms apply. Use code: VALENTINEBLISS Learn More" href="https://www.priceline.com/partner/v2/valentines-bliss" target="_blank"></a>  
                <div class="global-promo-banner-text not-mobile">  
                  <span class="global-promo-banner-headline"> 
                    <span><b>Valentine's Bliss:</b></span> 
                  </span>  
                  <span class="global-promo-banner-subheadline"> 
                    <span>Create cherished memories and save as much as $250 extra when you bundle. Terms apply. Use code: <b>VALENTINEBLISS </b><u>Learn More</u></span>
                  </span>
                </div>  
              </div>
             </div> 
             <header id="global-header" class="navbar navbar-priceline light-header fs-unmask">   
              <nav class="navbar-header no-padding">
                <div id="primary-nav" class="primary-nav-group header-redesign">
                  <div id="global-header-nav-section" class="nav-section">
                      <div class="navbar-hamburger" style={{visibility: 'hidden'}}>
                        <div class="header-left-icon-container">
                          <img src="https://s1.pclncdn.com/design-assets/gns/hamburger-blue.svg" width="24" height="24" alt="hamburger menu" loading="lazy" />
                        </div>
                      </div>
                      <a class="navbar-priceline-brand" href="/" target="_self" title="Priceline.com Home" data-autobot-element-id="DASH_HEADER_PRICELINE" data-ga-category="header" data-ga-action="click_logo" data-ga-label="priceline_logo">
                        {/* <svg width="87px" height="22px" role="img" aria-labelledby="go-to-priceline-homepage" viewBox="0 0 87 22" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         >
                          <title id="go-to-priceline-homepage">Go to Priceline Homepage</title>
                          <defs></defs>
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="priceline_blue" fill="#0068ef">
                              <g id="Group">
                                <path d="M78.7 10c0-.4-.1-1-.6-1.6a2.4 2.4 0 0 0-3.4.1c-.4.5-.5 1-.6 1.6h4.6zm3.3 4.3a9 9 0 0 1-1.3 1.7c-1 1-2.5 1.7-4.3 1.7-3.4 0-5.8-2.2-5.8-6 0-2.8 1.4-6.3 5.9-6.3.6 0 2.6 0 4 1.6 1.5 1.6 1.6 3.9 1.7 5.2H74c0 1.4.8 2.8 2.6 2.8 1.9 0 2.5-1.2 3-2l2.4 1.3zM62.6 6.8c.3-.4.6-.6 1.2-1 .5-.1 1.2-.3 2-.3.7 0 1.5 0 2 .4 1.3.7 1.6 1.7 1.6 3.5v7.8H66v-6.4L66 9c-.3-.6-.9-.8-1.5-.8-1.8 0-1.8 1.4-1.8 2.8v6.2h-3.4V5.8h3.4v1zm-5.5-4c0 1-.8 1.9-1.8 1.9s-1.8-.8-1.8-1.9c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8zm-3.4 3H57v11.4h-3.3V5.7zm-2.3-5v16.4H48V.8h3.4zM43 10c0-.5-.1-1.2-.6-1.7a2.4 2.4 0 0 0-3.4.1c-.4.5-.5 1-.6 1.6H43zm3.4 4.2A9 9 0 0 1 45 16c-1 1-2.5 1.7-4.3 1.7-3.4 0-5.8-2.2-5.8-6C35 9 36.4 5.4 41 5.4c.6 0 2.6 0 4 1.6 1.6 1.6 1.6 3.9 1.7 5.2h-8.2c0 1.4.8 2.8 2.6 2.8 1.9 0 2.5-1.2 3-2l2.5 1.3zm-13.2-.6l-.3.4c-.7.8-1.5.8-1.7.8-1.8 0-2.5-1.9-2.5-3.4 0-1.4.6-3.5 2.4-3.5.5 0 1 .2 1.2.4.5.3.7.7.9 1 .4-.9.8-1.8 1.5-2.5A5.5 5.5 0 0 0 31 5.4c-3.8 0-5.7 2.9-5.7 6.2 0 4.7 3.5 6 5.7 6a5.6 5.6 0 0 0 3.7-1.4 5.3 5.3 0 0 1-1.4-2.5zM24 2.8c0 1-.8 1.9-1.8 1.9s-1.8-.8-1.8-1.9c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8zm-3.5 3h3.3v11.4h-3.3V5.7zm-4.7 1.1c.7-.6 1.5-1.1 3.3-1.3v3l-1.1.2c-1.6.3-2.1.5-2.1 2v6.4h-3.4V5.7h3.3V7zM5.6 8a2 2 0 0 0-1.7.9c-.5.6-.7 1.6-.7 2.7 0 1.5.4 2.4.8 2.9.4.4 1 .6 1.5.6C7.2 15 8 13.2 8 11.4 7.9 10 7.5 8.3 6 8h-.5zM3 6.9c0-.2.2-.3.3-.4.6-.6 1.6-1 2.9-1 2.8 0 5 2.1 5 6 0 2.3-1.1 6-5 6-1.3 0-2-.5-2.8-1v4.7H0V5.8h3v1z" fill-rule="nonzero"></path><path d="M84.5 2a2.5 2.5 0 0 1 2.2 1.2c.2.4.3.8.3 1.3 0 .4-.1.9-.3 1.2a2.5 2.5 0 0 1-4.4 0c-.2-.3-.3-.7-.3-1.2 0-.4.1-.9.3-1.2A2.5 2.5 0 0 1 84.5 2zm0 4.4c.3 0 .7-.1 1-.3a2 2 0 0 0 .9-1.6 1.8 1.8 0 0 0-1-1.6c-.2-.2-.5-.3-.9-.3a1.8 1.8 0 0 0-1.6 1c-.2.2-.3.6-.3 1a1.9 1.9 0 0 0 1.9 1.8zm1-2.3v.4c-.1.2-.2.3-.4.3l.5.8H85l-.3-.7H84v.7h-.5V3.3h1c.3 0 .5 0 .7.2.2.1.3.3.3.6zm-1.4.4h.5l.3-.1V4v-.3H84v.7z"></path>
                              </g>
                            </g>
                          </g>
                        </svg> */}
                        <img src={logo} alt='logo' width={100} height={35}/>
                      </a>
                    <ul class="global-header-nav-product-list">  
                        <li class="global-header-nav-product-item global-header-nav-product-item-hotels">
                          <a id="link_header_nav_hotels" target="_blank" class="global-header-nav-product-link" href="/hotels/" data-autobot-element-id="DASH_DESKTOP_HEADER_HOTELS" data-ga-category="header" data-ga-action="click_nav" data-ga-label="header_nav_hotels">Hotels</a>
                        </li>   
                        <li class="global-header-nav-product-item global-header-nav-product-item-cars">
                            <a id="link_header_nav_cars" target="_blank" class="global-header-nav-product-link" href="/rentalcars/" data-autobot-element-id="DASH_DESKTOP_HEADER_CARS" data-ga-category="header" data-ga-action="click_nav" data-ga-label="header_nav_cars">Cars</a>
                        </li>   
                        <li class="global-header-nav-product-item global-header-nav-product-item-flights">
                            <a id="link_header_nav_flights" target="_blank" class="global-header-nav-product-link" href="/flights/" data-autobot-element-id="DASH_DESKTOP_HEADER_FLIGHTS" data-ga-category="header" data-ga-action="click_nav" data-ga-label="header_nav_flights">Flights</a>
                        </li>   
                        <li class="global-header-nav-product-item global-header-nav-product-item-packages">
                            <a id="link_header_nav_vacationpackages" target="_blank" class="global-header-nav-product-link" href="/vacationpackages/" data-ga-category="header" data-ga-action="click_nav" data-ga-label="header_nav_vacationpackages"> Bundle + Save </a>
                        </li>   
                        <li class="global-header-nav-product-item global-header-nav-product-item-cruises">
                            <a id="link_header_nav_cruises" target="_blank" class="global-header-nav-product-link" href="https://cruises.priceline.com/?utm_medium=partner_site_topnav&amp;utm_source=pclnhp_top_nav&amp;utm_campaign=globalnav&amp;utm_content=hp_top_nav_cruise" data-autobot-element-id="DASH_DESKTOP_HEADER_CRUISES" data-ga-category="header" data-ga-action="click_nav" data-ga-label="header_nav_cruise">Cruises</a>
                        </li>   
                        <li class="global-header-nav-product-item global-header-nav-product-item-experiences">
                            <a id="link_header_nav_experiences" target="_blank" class="global-header-nav-product-link" href="https://experiences.priceline.com?CID=hpgheaderDKTP" data-autobot-element-id="DASH_DESKTOP_HEADER_EXPERIENCES" data-ga-category="header" data-ga-action="click_nav" data-ga-label="header_nav_experiences">Experiences</a>
                        </li>  
                    </ul>        
                </div>
                  <div id="global-header-dropdown-section" class="dropdown-section vip-design" style={{visibility: 'hidden'}}>
                    <div id="multi-currency-module" class="list-item-group default-currency-no-mc">
                      <button aria-expanded="false" aria-controls="mc-dd" aria-label="N/A" class="navbar-btn btn-priceline " data-autobot-element-id="DASH_DESKTOP_HEADER_CURRENCY" id="in-path-multi-currency-click">
                        <div id="currency-label" class="primary-nav-font currency-label default-multi-currency-label">
                          N/A
                        </div>
                        <div id="currency-symbol" class="primary-nav-font currency-symbol default-multi-currency-label">
                        </div>
                      </button>
                      <ul id="mc-dd" class="global-header-dropdown-list mc-dropdown">  </ul>       
                    </div>
                    <div id="help-module" class="list-item-group help-group help-no-mc" style={{borderBottom: '8px solid transparent'}}>
                      <button aria-expanded="false" aria-controls="help-dd" aria-label="Help" class="navbar-btn btn-priceline btn-arrow" data-autobot-element-id="DASH_DESKTOP_HEADER_HELP" id="in-path-help-click">
                        <i class="nav-icon icon-utility-help"></i>
                        <span id="help" class="nav-title primary-nav-font">Help</span>
                      </button>
                      <ul id="help-dd" class="global-header-dropdown-list help-dropdown" style={{visibility: 'hidden', opacity: '0'}}>
                        <li class="global-header-dropdown-item">
                          <a class="global-header-dropdown-link" rel="nofollow" href="https://help.priceline.com" data-autobot-element-id="DASH_DESKTOP_HEADER_DROPDOWN_CONTACT" data-ga-category="header" data-ga-action="click_header" data-ga-label="contact_us">
                            Travel Support/Contact Us
                          </a>
                        </li>
                        <li class="global-header-dropdown-item"> 
                            <a class="global-header-dropdown-link" rel="nofollow" href="https://help.priceline.com" data-autobot-element-id="DASH_DESKTOP_HEADER_DROPDOWN_QUESTIONS" data-ga-category="header" data-ga-action="click_header" data-ga-label="faq">
                              Frequently Asked Questions
                            </a>
                        </li>   
                        <li class="global-header-dropdown-item">
                          <a class="global-header-dropdown-link" rel="nofollow" href="/next-profile/trip-lookup" data-autobot-element-id="DASH_DESKTOP_HEADER_DROPDOWN_REQUEST_NUM" data-ga-category="header" data-ga-action="click_header" data-ga-label="find_request_number">
                            Find Your Request Number
                          </a>
                        </li>
                        <li class="global-header-dropdown-item">
                          <a id="help-dd-password-help" href="#" class="global-header-dropdown-link" rel="nofollow" data-autobot-element-id="DASH_DESKTOP_HEADER_DROPDOWN_PROFILE_PASSWORD" data-ga-category="header" data-ga-action="click_header" data-ga-label="password_help">
                            Profile Password Help
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div id="my-trips-module" class="list-item-group my-trips-group my-trips-no-mc" style={{borderBottom: '8px solid transparent'}}>
                      <button aria-expanded="false" aria-controls="my-trips-dd" aria-label="Find My Trip" class="navbar-btn btn-priceline btn-arrow" data-autobot-element-id="DASH_DESKTOP_HEADER_MYTRIPS" id="in-path-my-trips-click">
                        <i class="nav-icon icon-utility-trips"></i>
                        <span id="myTrips-nav-title" class="nav-title primary-nav-font">Find My Trip</span>
                      </button>
                      <ul id="my-trips-dd" class="global-header-dropdown-list my-trips-dropdown" style={{visibility: 'hidden', opacity: '0'}}>
                        <li class="global-header-dropdown-item">
                          <a class="global-header-dropdown-link" rel="nofollow" href="/next-profile/trip-lookup" data-autobot-element-id="DASH_DESKTOP_HEADER_DROPDOWN_FIND_TRIP" data-ga-category="header" data-ga-action="click_header" data-ga-label="check_request_status">
                            Find My Trip
                          </a>
                        </li>
                        <li class="global-header-dropdown-item">
                          <a class="global-header-dropdown-link" rel="nofollow" href="/next-profile/trip-lookup" data-autobot-element-id="DASH_DESKTOP_HEADER_DROPDOWN_VIEW_ITINERARY" data-ga-category="header" data-ga-action="click_header" data-ga-label="view_itinerary">
                            View, Print or Email Your Itinerary
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div id="sign-in-module" data-autobot-element-id="DASH_HEADER_MENU_SIGNIN" class="fs-mask list-item-group sign-in-group sign-in-no-mc" style={{borderBottom: '8px solid transparent'}}>
                      <button aria-label="Sign in" class="navbar-btn btn-priceline btn-arrow" id="in-path-sign-in-out-click" type="button">   
                          <div id="sign-in-module__button-border">
                              <div id="sign-in-module__signedIn" class="signed-in-greeting node-invisible"><i class="nav-icon icon-utility-profile sign-in-module__desktop-name"></i><span id="sign-in-module__user-name-signedIn" class="primary-nav-font nav-title">
                                <span class="user-greeting">Hi,&nbsp;</span><span class="user-first-name"></span></span>
                              </div>    
                              <div id="sign-in-module__anonymous" class="anonymous-greeting"><i class="nav-icon icon-utility-svg"></i><span id="mobile-sign-in-title" class="sign-in-title-mobile-text nav-title"> Sign in </span>
                                  <span id="user-nav-sign-in" class="primary-nav-font nav-title"> Sign in </span>
                              </div>
                          </div>
                      </button> 
                      <section id="sign-in-dd" class="global-header-dropdown-list sign-in-menu" style={{visibility: 'hidden', opacity: '0'}}>
                        <div class="sign-in-menu-graphic">
                          <div class="sign-in-menu-graphic-outer">
                            <div class="sign-in-menu-graphic-outer-inner">
                              <div class="sign-in-menu-graphic-outer-inner-glyph">
                                <i class="icon-utility-unlock"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p class="sign-in-menu-deals-ad">
                          SIGN IN
                          <strong class="sign-in-menu-deals-ad-bold-prices">
                            UNLOCK LOWER HOTEL PRICES
                          </strong>
                        </p>
                        <a class="sign-in-menu-Wahl" href="/next-profile/login" data-autobot-element-id="DASH_DESKTOP_HEADER_MODAL_SIGNIN" data-ga-category="signin" data-ga-action="click_signin" data-ga-label="signin">
                            Sign In Now!
                        </a>
                      </section>
                      <section id="sign-out-dd" class="global-header-dropdown-list sign-out-menu node-invisible" style={{visibility: 'hidden', opacity: '0'}}>
                          <div id="sign-out-menu-greeting" class="sign-out-menu-greeting"> Hi,&nbsp;
                            <div class="user-first-name"></div>
                          </div>
                          <div id="sign-out-email" class="sign-out-menu-email"> Email:  <span id="sign-out-menu-email-address"></span>  </div>
                          <a class="sign-out-menu-button" href="/next-profile/profile" id="my-profile-link" data-ga-category="signin" data-ga-action="click_my_profile" data-ga-label="my_profile"><span class="sign-out-menu-button-text">My Profile</span></a>
                          <hr class="sign-out-menu-hr" />
                          <a class="sign-out-menu-button sign_out" href="#" id="sign-out-link" data-ga-category="signin" data-ga-action="click_signout" data-ga-label="sign_out"><span class="sign-out-menu-button-text">Sign out</span></a>
                      </section>
                    </div>
                    <div id="vip-badge" class="list-item-group">
                      <a href="/vip" class="navbar-btn btn-priceline" data-autobot-element-id="DASH_DESKTOP_HEADER_VIP_BADGE" data-ga-category="header" data-ga-action="click join vip">
                        <div class="vip-badge">
                            <span class="nav-title primary-nav-font">Join VIP</span>
                          <img class="vip-badge-icon" src="https://s1.pclncdn.com/design-assets/gns/vip-badges/join-vip-badge.svg" width="30" height="30" alt="VIP Badge Icon" loading="lazy" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>
              <div id="in-path-sign-in-out-outlet" class="in-path-authorization-outlet hide" data-hidden="true">
                <div id="in-path-sign-in" class="in-path-sign-in col-xs-12 hidden no-padding"></div>
                <div id="in-path-sign-out" class="in-path-sign-out col-xs-12 hidden no-padding"></div>
              </div>
             </header>
             <section id="hamburger-section" class="hamburger-section node-invisible light-header header-redesign">
              <header id="mobile-menu-header" class="hamburger-section__header">
                <div class="navbar-hamburger" data-autobot-element-id="DASH_MWEB_HEADER_MENU_ICON" data-ga-category="M-Menu" data-ga-action="Back to Page" data-ga-label="Back to Page">
                  <div class="header-left-icon-container">
                      <img src="https://s1.pclncdn.com/design-assets/gns/close-blue.svg" alt="close icon" loading="lazy" />
                  </div>
                </div>
                <img class="hamburger-menu-pcln-logo" src="https://s1.pclncdn.com/design-assets/gns/pcln-blue.svg" alt="Priceline Logo" loading="lazy" />
              </header>
              <div id="mobile-menu" class="hamburger-section__menu">
                <section class="nav-menu" role="mobile-navigation-menu">
                  <ul>
                    <li id="nav-menu-sign-in" class="nav-menu__sign-in node-bootstrap-invisible" data-ga-category="M-Menu" data-ga-action="click_signin" data-ga-label="signin">
                        <a class="nav-menu__sign-in-subtext-link" data-autobot-element-id="DASH_MWEB_HEADER_SIGNIN_BUTTON" tabindex="0">Sign in</a>
                    </li>
                    <li id="nav-menu-sign-out" class="nav-menu__sign-out node-invisible node-bootstrap-invisible">
                        <span id="nav-menu-sign-out-greeting" class="nav-menu__greeting"> Hi,&nbsp;<span class="user-first-name"></span></span>
                        <a id="nav-menu-sign-out-link" class="nav-menu__sign-out-link" data-ga-category="M-Menu" data-ga-action="click_signout" data-ga-label="signout"> Sign out </a>
                    </li>  
                    <li class="nav-menu__link">
                      <a id="nav-menu-link-hotels" class="nav-menu__link-anchor" href="/hotels/" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_HOTELS" data-ga-category="M-Menu" data-ga-action="click_nav" data-ga-label="Hotel">
                        <div class="nav-menu__link-anchor-icon-circle-container">
                          <img class="icon-background nav-menu__link-anchor-icon-circle" src="https://s1.pclncdn.com/design-assets/gns/hotels-outline.svg" alt="Hotels Icon" width="32px" height="32px" loading="lazy" />
                        </div>
                        <span class="nav-menu__link-anchor-label">Hotels</span>
                      </a>
                    </li>   
                    <li class="nav-menu__link">
                        <a id="nav-menu-link-flights" class="nav-menu__link-anchor" href="/flights/" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_FLIGHTS" data-ga-category="M-Menu" data-ga-action="click_nav" data-ga-label="Flight">
                          <div class="nav-menu__link-anchor-icon-circle-container">
                            <img class="icon-background nav-menu__link-anchor-icon-circle" src="https://s1.pclncdn.com/design-assets/gns/flights-outline.svg" alt="Flights Icon" width="32px" height="32px" loading="lazy" />
                          </div>
                          <span class="nav-menu__link-anchor-label">Flights</span>
                        </a>
                    </li>   
                    <li class="nav-menu__link">
                        <a id="nav-menu-link-rental-cars" class="nav-menu__link-anchor" href="/rentalcars/" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_CARS" data-ga-category="M-Menu" data-ga-action="click_nav" data-ga-label="Rental Car">
                          <div class="nav-menu__link-anchor-icon-circle-container">
                              <img class="icon-background nav-menu__link-anchor-icon-circle" src="https://s1.pclncdn.com/design-assets/gns/cars-outline.svg" alt="Cars Icon" width="32px" height="32px" loading="lazy" />
                          </div>
                          <span class="nav-menu__link-anchor-label">Rental Cars</span>
                        </a>
                    </li>   
                    <li id="nav-menu-vacation-package-item" class="nav-menu__link">
                        <a class="nav-menu__link-anchor" href="/vacationpackages/" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_PACKAGES" data-ga-category="M-Menu" data-ga-action="click_nav" data-ga-label="Vacation Packages">
                          <div class="nav-menu__link-anchor-icon-circle-container">
                              <img class="icon-background nav-menu__link-anchor-icon-circle" src="https://s1.pclncdn.com/design-assets/gns/packages-outline.svg" alt="Vacations Icon" width="32px" height="32px" loading="lazy" />
                          </div>
                          <span class="nav-menu__link-anchor-label">Vacation Packages</span>
                        </a>
                    </li>   
                    <li class="nav-menu__link">
                        <a id="nav-menu-link-cruises" class="nav-menu__link-anchor" href="https://cruises.priceline.com/?utm_medium=partner_site_topnav&amp;utm_source=pclnhp_top_nav&amp;utm_campaign=globalnav&amp;utm_content=hp_top_nav_cruise" data-autobot-element-id="DASH_MWEB_HEADER_MENU_CRUISES" data-ga-category="M-Menu" data-ga-action="click_nav" data-ga-label="Cruises">
                          <div class="nav-menu__link-anchor-icon-circle-container">
                              <img class="icon-background nav-menu__link-anchor-icon-circle" src="https://s1.pclncdn.com/design-assets/gns/cruises-outline.svg" alt="Cruises Icon" width="32px" height="32px" loading="lazy" />
                            </div>
                            <span class="nav-menu__link-anchor-label">Cruises</span>
                        </a>
                    </li>   
                    <li class="nav-menu__link">
                      <a id="nav-menu-link-experiences" class="nav-menu__link-anchor" href="https://experiences.priceline.com?CID=mobileicon" data-autobot-element-id="DASH_MWEB_HEADER_MENU_EXPERIENCES" data-ga-category="M-Menu" data-ga-action="click_nav" data-ga-label="Experiences">
                          <div class="nav-menu__link-anchor-icon-circle-container">
                              <img class="icon-background nav-menu__link-anchor-icon-circle" src="https://s1.pclncdn.com/design-assets/gns/experiences-outline.svg" alt="Experiences Icon" width="32px" height="32px" loading="lazy" />
                          </div>
                          <span class="nav-menu__link-anchor-label">Experiences</span>
                      </a>
                    </li>  
                  </ul>
                </section>
                <section class="aux-menu" role="mobile-auxiliary-menu">
                  <ul>
                      <li class="aux-menu__link">
                          <a class="aux-menu__link-anchor" href="/home/" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_HOME" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="Home"> Home </a>
                      </li>   
                      <li class="aux-menu__link">
                          <a class="aux-menu__link-anchor" href="/next-profile/trip-lookup" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_FIND_TRIP" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="Look Up Trip"> Find My Trip </a>
                      </li>  
                      <li id="mobile-default-currency" class="aux-menu__link aux-menu__with-dropdown default-currency-no-mc">
                          <div id="currency-spinner-title" class="aux-menu__link-currency-statics aux-menu__label">
                              <span class="aux-menu__link-currency-statics-title"> Currency:</span>
                              <span id="aux-menu-selected-currency" class="aux-menu__link-currency-statics-value">undefined</span>
                          </div>
                          <select style={{display: 'none'}} id="aux-menu-currency-spinner-select" title="currency-spinner-select" class="aux-menu__link-currency-spinner aux-menu__dropdown">  </select>
                      </li>   
                      <li class="aux-menu__link">
                          <a class="aux-menu__link-anchor" href="https://help.priceline.com" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_HELP" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="Help"> Help/Travel Support </a>
                      </li>  
                      <li class="aux-menu__link">
                          <a id="download-the-app" class="aux-menu__link-anchor" href="/r/app/?channel=Display&amp;product=App&amp;theme=app_dl_install&amp;PAGEID=MWEB_HAMBURGER_MENU&amp;sub_site_id=94551756aa55aa65693a538251c00da0" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_DOWNLOAD_THE_APP" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="Download the App"> Download the App </a>
                      </li>  
                      <li class="aux-menu__link">
                          <a class="aux-menu__link-anchor" href="https://careers.priceline.com/" data-autobot-element-id="DASH_MWEB_HEADER_MENU_CAREERS" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="Career"> Careers </a>
                      </li>  
                      <li class="aux-menu__link">
                        <a class="aux-menu__link-anchor" href="/static-pages/terms_en.html" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_TC" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="Terms and Conditions"> Terms &amp; Conditions </a>
                      </li>
                      <li class="aux-menu__link global-footer__ccpa-link" style={{display:'none'}}>
                        <a class="aux-menu__link-anchor" rel="nofollow" href="/dsarwebform" target="_self" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="do_not_sell_my_info">Do Not Sell My Personal Information</a>
                      </li>
                      <li class="aux-menu__link">
                          <a class="aux-menu__link-anchor" href="/static-pages/privacy-policy.html" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_PRIVACY_POLICY" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="Privacy Policy"> Privacy Policy </a>
                      </li>
                      <li class="aux-menu__link">
                          <a class="aux-menu__link-anchor" href="https://www.priceline.com/partner/v2/corporate-contact-information" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_CORPORATE_CONTACT_INFORMATION" data-ga-category="M-Menu" data-ga-action="click_menu_item" data-ga-label="Corporate Contact Information"> Corporate Contact Information </a>
                      </li>
                      <li id="aux-menu-link-desktop-site" class="aux-menu__link node-invisible">
                          <a class="aux-menu__link-anchor" href="/?plf=PCLN&amp;mde=m" target="_self" data-autobot-element-id="DASH_MWEB_HEADER_MENU_DESKTOP_SITES"> Desktop Site </a>
                      </li>
                  </ul>
                </section>
              </div>
             </section>
             <link rel="dns-prefetch" href="https://ids.priceline.com/" />
             <link rel="preconnect" href="https://ids.priceline.com/" crossorigin="anonymous" />
             <link rel="dns-prefetch" href="https://global.oktacdn.com/" />
             <link rel="preconnect" href="https://global.oktacdn.com/" crossorigin="anonymous" />
             <div id="pcln-okta-widget-placeholder" data-okta-client-id="0oa57zxal5Rb4nbSf696" data-okta-domain="ids.priceline.com" data-cguid="94551756aa55aa65693a538251c00da0">
                <div class="pcln-okta-widget-outer">
                  <div class="pcln-okta-widget-inner">
                    <button style={{display: 'block'}} id="pcln-okta-widget-close" data-testid="modal-closing-group">close</button>
                    <div id="pcln-okta-widget-target"></div>
                    <div id="pcln-okta-widget-branding"></div>
                  </div>
                </div>
             </div>
          </div>
        </div>
      </div>
      {/* <header>
        <div className='navbar-top'>
          <div className='row'>
            <div className='col-lg-4 col-md-4 navbar-img col-sm-12'>
              <img src={logo} alt='Logo' height='60' />
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12'>
              <div className='navbar-top-right '>
                <div className='d-flex  '>
                  <FontAwesomeIcon icon={faPhone} />
                  <h6>0121 777 2522</h6>
                </div>
                <div className='d-flex  '>
                  <button
                    onClick={toggleModal}
                    className='btn btn-primary currency-btn'
                  >
                    {selectedCurrency}
                  </button>
                  <I18nextProvider i18n={i18n}>
                      <LanguageSwitcher language="arabic" />
                    </I18nextProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav
          id='navbar'
          className={
            'navbar-expand-lg pb-2  navbar-dark   ' +
            (isMobileNavOpen ? 'navbar-mobile' : 'navbar')
          }
        >
          <button
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            className='navbar-toggler'
          >
            {isMobileNavOpen ? (
              <span
                style={{ color: 'white' }}
                onClick={toggleMobileNav}
                className='mobile-nav-toggle '
              >
                {' '}
                <FontAwesomeIcon icon={faClose} />
              </span>
            ) : (
              <span
                onClick={toggleMobileNav}
                className='mobile-nav-toggle navbar-toggler-icon'
              ></span>
            )}
          </button>
          <ul className='navbar-nav navbar-link  ml-auto'>
            <li className='nav-item nav-link scrollto  active'>
              <NavLink className='nav-link' to='/'>
              {t('Home')}
              </NavLink>
            </li>
            <li className='nav-item ps-3 dropdown nav-link scrollto'>
              <h5 onClick={toggleDropdown} className='nav-link'>
                 {t('Packages')}{' '}
                <FontAwesomeIcon onClick={toggleDropdown} icon={faAngleDown} />
              </h5>
              {isDropdownOpen2 && (
                <ul>
                  {dropdownCat.map((item, index) => (
                    <li key={index} className='mb-3'>
                      <h6
                        onClick={() => FindPackageDetail(item.id)}
                        className='dropdown-nav-link '
                      >
                         {t(`${item.title}`)}
                      </h6>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/hajj'>
              <FontAwesomeIcon  className='fa-bold fw-bold' icon={faKaaba} />{' '}
              {t('Hajj 2024')} 
              </NavLink>
              <span class=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/about-us'>
              {t('About Us')} 
              </NavLink>
              <span class=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/contact-us'>
              {t('Contact Us')}
              </NavLink>
              <span className=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
          </ul>
        </nav>
      </header> */}

      {/*********** Cuurency  Model ****************/}
      <Modal
        style={{ maxWidth: '90%' }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Select Your Currency</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>
              Where applicable, prices will be converted to—and shown in—the
              currency you select. The currency you pay in may differ based on
              your reservation, and a service fee may also apply.
            </p>
            <div className='border-line mt-2 mb-2'></div>
            {/* <div className='row mt-2'>
         <h5>Suggested for you</h5>
       </div> */}
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5>All Currencies</h5>
                <input
                  type='text'
                  value={searchValue}
                  onChange={SearchValue}
                  placeholder='Search'
                />
              </div>

              {searchValue === ''
                ? // Render the full list when there is no search input
                  countryList.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))
                : // Render the filtered list when there is a search input
                  userCountry.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Navbar
