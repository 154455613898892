import React,{useState,useEffect} from "react";
import bgimage from '../../Images/Transfer/transfercover.jfif';
import Layout from "../../Components/Layout/Layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { RangeSlider } from 'rsuite';
import TransferCard from "../../Components/Transfer/TransferCard";
import TransferSearch from "../../Components/SearchBar/Transfers";
function TransferListing(){
  var minValue1=0;
  var maxValue1 = 0;
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const TransferDetail=useSelector((state) => state.hotels.TrSeResponse);
  const [showfilter, setShowfilter] = useState({price:true});
  if(TransferDetail?.transfers_list !== 0){
   var amounts = TransferDetail?.transfers_list.map(item => item.total_fare_markup);
    if(amounts.length !==0){
      minValue1 = Math.min(...amounts);
       maxValue1 = Math.max(...amounts);
    };
  };
const [minValue, setMinValue] = useState(minValue1);
const [maxValue, setMaxValue] = useState(maxValue1);
const [rangeValue, setRangeValue] = useState([minValue1, maxValue1]);
const [FilterData, setFilterData] = useState(TransferDetail?.transfers_list);

  const handleChange = (newRangeValue) => {
    setRangeValue(newRangeValue);
    setMinValue(newRangeValue[0]);
    setMaxValue(newRangeValue[1]);
  };

  const filterbyPrice=()=>{
    const filteredTours = TransferDetail?.transfers_list.filter(item => {
      const price = item.total_fare_markup;
      return price >= minValue && price <= maxValue;
    });
    setFilterData(filteredTours);
  };
  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      var baseprice =price;
    } else {
      const conversionrate = GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency];
      var newprice = (Number(conversionrate) * Number(price)).toFixed(0);
      var baseprice =newprice;
     
    }
    return baseprice
  };
  const Showfilter=(num)=>{
    if(num===1){
      setShowfilter(prevData => ({ ...prevData, price: !prevData.price }));
    }
  };

    return(
        <>
        <Layout>
             <div className="container-fluid px-0">
                <div className="modify-flight-search" style={{marginTop:'1px'}}><TransferSearch/></div>
             </div>
             <div className='container-fluid'>
                <div className='row mt-3 pb-5'>
                  <div className='col-md-3 col-md-pull-9 px-4'>
                    <div className='page-sidebar '>
                      <div className='widget widget_price_filter'>
                        <div className='filter-show-hide bwCBvh kuQtDu mt-2' onClick={()=>Showfilter(1)}>                
                          <h3 className='fMYYEI'>Price Filter</h3>
                          <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i>
                        </div>
                        {showfilter.price && (
                        <div className='edRKIj gCLia-d'>
                          <RangeSlider
                              value={rangeValue}
                              onChange={handleChange}
                              min={minValue1-1}
                              tooltip={false}
                              max={maxValue1+1}
                              step={1}
                            />
                            <div className='pt-2 d-flex' style={{justifyContent:'space-between'}}>
                              <div className='fw-bold mb-2'>
                                Min: <span id='kt_slider_basic_min'>{renderPrice(minValue)}</span>
                              </div>
                              <div className='fw-bold mb-2'>
                                Max: <span id='kt_slider_basic_max'>{renderPrice(maxValue)}</span>
                              </div>
                          </div>
                          <button onClick={filterbyPrice}  className='HWMyp w-100'>Filter</button>
                        </div>
                        )}
                      </div>
              {/* <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' >                
                <h3>Star Rating</h3>                
                <FontAwesomeIcon icon={faAngleDown}/>
                </div>
               <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button  className='btn select-styling search-btn1'>Filter</button>
                </div>
                
              </div> */}
                    </div>
                  </div>
                  <div className='col-md-9 col-md-push-3'>
                    <div className='mb-3'>
                      <h6 className='title' style={{fontSize:'18px', color:'black'}} id='tours_result'>
                        {TransferDetail?.transfers_list.length} Result Found
                      </h6>
                    </div>
                      <TransferCard  TransferData={FilterData}/>
                  </div>
                </div>
             </div>
        </Layout>

        </>
    );
}

export default TransferListing;