import React, { useState, useCallback,useEffect } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLeft, faSearch,faMinus,faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from 'react-redux';
import {FlightSearchToken,ApiEndPoint} from '../GlobalData/GlobalData'
import { airportcode } from '../Data/AirportCodes'
import { OneWayFlightSearchData,OneWayFlightIndexSearchData } from '../../Redux/Actions/actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import Loader from "../Loading/Loader";
import { ToastContainer, toast } from 'react-toastify';
import { AutoComplete } from 'rsuite'
const optionsPerPage = 10
function ModifyFlightSearch () {
  const {t}=useTranslation();
  const currentDir = document.body.dir;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var endpoint=ApiEndPoint();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('')
  const [visibleOptions, setVisibleOptions] = useState(optionsPerPage)
  const [isClearable, setIsClearable] = useState(true)
  const [onewayDDate, setOnewayDDate] = useState('')
    const [onewayRDate, setOnewayRDate] = useState('')
    const [detailModal, setDetailModal] = useState(false);
  const [onewaySelectChangeto, setOnewaySelectChangeto] = useState(null)
  const [onewaySelectChangefrom, setOnewaySelectChangefrom] = useState(null)
  const [onewaySelectChangecabin, setOnewaySelectChangecabin] = useState('')
  const [onewaySelectChangestop, setOnewaySelectChangestop] = useState('')
  const AirportCode = airportcode.items;
  const [onewayPData, setOnewayPData] = useState({
    adults: 1,
    childs: 0,
    infants: 0
  });
  const [activeTab, setActiveTab] = useState('tab1');
  const [tripType, setTripType] = useState('oneway'); // 'option2' is the default value for 'One Way'

  const handleRadioChange = (event) => {
    setTripType(event.target.value);
  };
  const tabs = [
    { id: 'tab1', label: 'OneWay', content: 'Content for Tab 1' },
    { id: 'tab2', label: 'Return', content: 'Content for Tab 2' }
  ];
  useEffect(()=>{
    GetFlighMarkup();
  },[]);
  const handleTabChange = tab => {
    setActiveTab(tab);
  };
  const toggleDetailModal = () => {
   setDetailModal(!detailModal);
  };
  const handleOnewayDDate = date => {
    setOnewayDDate(date)
  };
  const handlePersonOnchange = (catogry,action) => {
    var num=0;
    if(catogry===1){
      num=onewayPData.adults;
      if(action===1){
        if(Number(num-1<=0)){
          return;
        }
        setOnewayPData(prevdata => ({
          ...prevdata,
          adults: Number(onewayPData.adults)-1
        }))
      }else if(action===2){
        setOnewayPData(prevdata => ({
          ...prevdata,
          adults: Number(onewayPData.adults)+1
        }))
      }
    }else if(catogry===2){
      num=onewayPData.childs;
      if(action===1){
        if(Number(num-1<0)){
          return;
        }
        setOnewayPData(prevdata => ({
          ...prevdata,
          childs: Number(onewayPData.childs)-1
        }))
      }else if(action===2){
        setOnewayPData(prevdata => ({
          ...prevdata,
          childs: Number(onewayPData.childs)+1
        }))
      }
    }else if(catogry===3){
      num=onewayPData.infants;
      if(action===1){
        if(Number(num-1<0)){
          return;
        }
        setOnewayPData(prevdata => ({
          ...prevdata,
          infants: Number(onewayPData.infants)-1
        }))
      }else if(action===2){
        setOnewayPData(prevdata => ({
          ...prevdata,
          infants: Number(onewayPData.infants)+1
        }))
      }
    }
    
  };
  const handleOnewayRDate = date => {
    setOnewayRDate(date)
  };
 
  const handleOneWaySelectchangeto = selectedOption => {
    setOnewaySelectChangeto(selectedOption)
  };
  const handleOneWaySelectchangefrom = selectedOption => {
    setOnewaySelectChangefrom(selectedOption)
  };
  const handleOneWaySelectchangecabin = event => {
    setOnewaySelectChangecabin(event.target.value)
  };
  const handleOneWaySelectchangestop = event => {
    setOnewaySelectChangestop(event.target.value)
  };
  const handleInputChange = inputValue => {
    const lowerCaseInput = inputValue.toLowerCase()
    setSearchTerm(lowerCaseInput)
    setVisibleOptions(optionsPerPage) // Reset visible options when searching
  }

  const filteredOptions = airportcode.items.filter(option =>
    option.cityName.toLowerCase().includes(searchTerm) ||
    option.cityCode.toLowerCase().includes(searchTerm)
  );

  function generateRandomNumber() {
    const min = Math.pow(10, 14); // Minimum 15-digit number
    const max = Math.pow(10, 15) - 1; // Maximum 15-digit number
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const OneWayapicall=async(type)=>{
     if(onewaySelectChangefrom===null){
      toast.info('Please Select Departure From.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    } if(onewaySelectChangeto===null){
      toast.info('Please Select Departure To.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else if(onewayDDate ===''){
      toast.info('Please Select Departure Date.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    };

    if(tripType !=="" && tripType ==="return"){
      if(onewayRDate===''){
        toast.info('Please Select Return Date.', {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
    };
    const random15DigitNumber = generateRandomNumber();
    var token=FlightSearchToken();
    const data={
      'token_authorization':token,
      'case':'search_flights',
      'MaxStopsQuantity':onewaySelectChangestop==='' ? 'All':onewaySelectChangestop,
      'DepartureDate':moment(onewayDDate).format('YYYY-MM-DD'),
     'DepartureCode':onewaySelectChangefrom.value,
     'ArrivalCode':onewaySelectChangeto.value,
      'AirTripType': tripType === "return" ? 'Return' : 'OneWay',
      'AirlinesCode':'EK',
      'adult':onewayPData.adults,
      'child':onewayPData.childs,
      'infant':onewayPData.infants,
      'ConversationId':random15DigitNumber,
      'CabinType':onewaySelectChangecabin==='' ? 'no':onewaySelectChangecabin,
    };
    if (tripType !=="" && tripType ==="return") {
      data['return_date'] = moment(onewayRDate).format('YYYY-MM-DD');
    }
    dispatch(OneWayFlightIndexSearchData(data));
    setLoading(true);
    try {
       const response = await Axios.post(endpoint+'/api/flight_search_Live',data, {
         headers: {
           "Access-Control-Allow-Origin": "*",
         } ,
         
       });
       setLoading(false);
       if(response.data.Success===false){
        toast.warning(response.data.Message, {
          position: toast.POSITION.TOP_RIGHT
        });
       }else{
        dispatch(OneWayFlightSearchData(response.data.Data));
        sessionStorage.setItem('15digitnumber', random15DigitNumber.toString());
         navigate('/Flight_search');
       }
   
     } catch (error) {
       // Handle errors here
       setLoading(false);
       console.error('Error:', error);
     }

  };

  const GetFlighMarkup=async()=>{
    var token=FlightSearchToken();
      var data={
        'token':token,
      };
    try{
      const response=await Axios.post(endpoint+'/api/get_markup_flight_new_Live',data,{
        headers:{
          "Access-Control-Allow-Origin": "*",

        }
      });
      localStorage.setItem('FlightMarkup',JSON.stringify(response.data));
    } catch (error){
        console.error('Error',error);
    }

  };

  return (
    <>
     {loading && (
            <Loader/>
        )}
        <ToastContainer/>
      <Modal isOpen={detailModal}   centered={true}  toggle={toggleDetailModal}>
        <ModalHeader toggle={toggleDetailModal}>Select Detail</ModalHeader>
        <ModalBody>
          <div className='p-3'>
            <div>
           <div>
           <div className='select-child'>
             <div class='f4878764f1'>
               <label class='a984a491d9 fw-bold' for='group_adults'>
                 Adults
               </label>
             </div>
             <div class='d-flex fff'>
               <button
                 className='adult-modal-btn'
                 onClick={() => handlePersonOnchange(1,1)}
                 
               >
                 <i class='fas fa-minus'>
                   <FontAwesomeIcon size='sm' icon={faMinus} />
                 </i>
               </button>
               <span className='d723d73d5f fw-bold' id='input'>
               {onewayPData.adults}
               </span>
               <button
                 className='adult-modal-btn'
                 onClick={() => handlePersonOnchange(1,2)}
                 id='increment'
               >
                 <i class='fas fa-plus'>
                   <FontAwesomeIcon size='sm' icon={faPlus} />
                 </i>
               </button>
             </div>
           </div>
           <div className='select-child'>
             <div class='f4878764f1'>
               <label class='a984a491d9 fw-bold' for='group_adults'>
                 Children
               </label>
             </div>
             <div class='d-flex fff'>
               <button
                 className='adult-modal-btn'
                 onClick={() => handlePersonOnchange(2,1)}
                 id='decrement'
               >
                 <i class='fas fa-minus'>
                   <FontAwesomeIcon size='sm' icon={faMinus} />
                 </i>
               </button>
               <span className='d723d73d5f fw-bold' id='input'>
                 {onewayPData.childs}
               </span>
               <button
                 className='adult-modal-btn'
                 onClick={() => handlePersonOnchange(2,2)}
                 id='increment'
               >
                 <i class='fas fa-plus'>
                   <FontAwesomeIcon size='sm' icon={faPlus} />
                 </i>
               </button>
             </div>
           </div>
           <div className='select-child'>
             <div class='f4878764f1'>
               <label class='a984a491d9 fw-bold' for='group_adults'>
                 Infant
               </label>
             </div>
             <div class='d-flex fff'>
               <button
                 className='adult-modal-btn'
                 onClick={() => handlePersonOnchange(3,1)}
                 id='decrement'
               >
                 <i class='fas fa-minus'>
                   <FontAwesomeIcon size='sm' icon={faMinus} />
                 </i>
               </button>
               <span className='d723d73d5f fw-bold' id='input'>
                 {onewayPData.infants}
               </span>
               <button
                 className='adult-modal-btn'
                 onClick={() => handlePersonOnchange(3,2)}
                 id='increment'
               >
                 <i class='fas fa-plus'>
                   <FontAwesomeIcon size='sm' icon={faPlus} />
                 </i>
               </button>
             </div>
           </div>
         </div>
         </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggleDetailModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div>
        <div className='tab-content'>
          <div id="panel-flights" class="sc-aXZVg dUQmGt">
            <form aria-label="flight-search-form">
              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dgSpnW brHOWu">
                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 kltLjW llNdfK">
                  <label font-size="10,12" font-weight="500" color="text.light" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 sc-df62962f-0 fRRaLj jAHCNY d-flex">
                    <div color="primary" data-name="unchecked" class="Radio__RadioWrap-pcln-design-system__sc-7dqaqw-0 lnsQhS">
                      <input type="radio" color="primary" size="24" class="Radio__RadioInput-pcln-design-system__sc-7dqaqw-1 iOWFYt" value="round" checked={tripType === 'round'}
                        onChange={handleRadioChange} />
                    </div><div className='mx-1' style={{marginTop:'2px'}}>{t('Round Trip')}</div>
                  </label>
                </div>
                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 kltLjW llNdfK">
                  <label font-size="10,12" font-weight="500" color="text.light" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 sc-df62962f-0 fRRaLj jAHCNY d-flex">
                    <div color="primary" data-name="unchecked" class="Radio__RadioWrap-pcln-design-system__sc-7dqaqw-0 ehUrZJ lnsQhS">
                      <input type="radio" color="primary" size="24" class="Radio__RadioInput-pcln-design-system__sc-7dqaqw-1 iOWFYt" value="oneway" checked={tripType === 'oneway'}
                             onChange={handleRadioChange}  />
                    </div> <div className='mx-1' style={{marginTop:'2px'}}>{t('One-way')}</div>
                  </label>
                </div>
                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 kltLjW llNdfK">
                   <label font-size="10,12" font-weight="500" color="text.light" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 sc-df62962f-0 fRRaLj jAHCNY d-flex">
                     <div color="primary" data-name="unchecked" class="Radio__RadioWrap-pcln-design-system__sc-7dqaqw-0 ehUrZJ lnsQhS">
                       <input type="radio" color="primary" size="24" class="Radio__RadioInput-pcln-design-system__sc-7dqaqw-1 iOWFYt" value="return" checked={tripType === 'return'}
                               onChange={handleRadioChange}  />
                     </div><div className='mx-1' style={{marginTop:'2px'}}>{t('Return')}</div>
                  </label>
                </div>
              </div>
              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 csGAWq koPElt">
                <div width="1,,0.5,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 fPtMyk" style={{width:'33%'}}>
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                    <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                      <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="flights.0.startLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw" style={{position: 'relative'}}>
                        <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 sc-20db91c2-0 bXyETw gSCCYN">
                          <div color="background.lightest" class="Box-pcln-design-system__sc-n9h3nv-0 hhIGyZ">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                              <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 kFfTeO">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 FormField__DisableableBox-pcln-design-system__sc-tx1s9e-0 WjkcY">
                                    <label width="auto" for="flights.0.startLocation-typeahead-downshift-container-input" id="flights.0.startLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 ezPCvJ"
                                       style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Departing from?</label>
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK" style={{border:'1px solid rgb(192, 202, 213)', borderRadius:'12px'}}>
                                      <div data-testid="icon-field-prefix-icons" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 danbLM brHOWu">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                          <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                        </svg>
                                      </div>
                                      <Select id="flights.0.startLocation-typeahead-downshift-container-input" data-testid="flights.0.startLocation-typeahead-input" aria-autocomplete="list" aria-labelledby="flights.0.startLocation-typeahead-downshift-container-label" 
                                          placeholder={t('Depating from?')} font-size="2,,1" class="Input__StyledInput-pcln-design-system__sc-7wuyrc-0 VtYfX" 
                                          value={onewaySelectChangefrom} onChange={handleOneWaySelectchangefrom} onInputChange={handleInputChange}
                                          options={filteredOptions.slice(0, visibleOptions)
                                                    .map(option => ({
                                                      value: option.airportCode,
                                                      label:
                                                        option.airportCode +
                                                        '-' +
                                                        option.cityName +
                                                        '-' +
                                                        option.countryName
                                                    }))}
                                          isClearable={true} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div width="1,,0.5,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 fPtMyk"  style={{width:'33%'}}>
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                    <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                      <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="flights.0.endLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw" style={{position: 'relative'}}>
                        <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 sc-20db91c2-0 bXyETw gSCCYN">
                          <div color="background.lightest" class="Box-pcln-design-system__sc-n9h3nv-0 hhIGyZ">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                              <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 kFfTeO">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 FormField__DisableableBox-pcln-design-system__sc-tx1s9e-0 WjkcY">
                                    <label width="auto" for="flights.0.endLocation-typeahead-downshift-container-input" id="flights.0.endLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 ezPCvJ"
                                       style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Going to?</label>
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK"  style={{border:'1px solid rgb(192, 202, 213)', borderRadius:'12px'}}>
                                      <div data-testid="icon-field-prefix-icons" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 danbLM brHOWu">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                          <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                        </svg>
                                      </div>
                                      <Select id="flights.0.endLocation-typeahead-downshift-container-input" data-testid="flights.0.endLocation-typeahead-input" aria-autocomplete="list" 
                                              aria-labelledby="flights.0.endLocation-typeahead-downshift-container-label" font-size="2,,1" 
                                              class="Input__StyledInput-pcln-design-system__sc-7wuyrc-0 VtYfX" value={onewaySelectChangeto}  onChange={handleOneWaySelectchangeto}
                                              onInputChange={handleInputChange} placeholder={t('Going to?')}
                                              options={filteredOptions
                                                .slice(0, visibleOptions)
                                                .map(option => ({
                                                  value: option.airportCode,
                                                  label:
                                                    option.airportCode +
                                                    '-' +
                                                    option.cityName +
                                                    '-' +
                                                    option.countryName
                                                }))}
                                              isClearable={true}
                                        />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div width="1,,,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 kRXxeM eplQeC"  style={{width:'33%'}}>
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                    <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 sc-20db91c2-0 bXyETw gSCCYN">
                        <div color="background.lightest" class="Box-pcln-design-system__sc-n9h3nv-0 hhIGyZ">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 kFfTeO">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <button width="1" color="primary" aria-expanded="false" tabindex="0" type="button"  role="combobox" aria-labelledby="id-1707298637790" aria-invalid="false" data-value="false" 
                                        class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 gCTxcz sc-2e703bfe-1 hyaGGX">
                                  <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gLnKRh EZYWO">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iqzbxV Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                    </svg>
                                    <DatePicker
                                      className='datepicker'
                                      selected={onewayDDate}
                                      onChange={handleOnewayDDate}
                                      minDate={new Date()}
                                      placeholderText={t('Departing')}
                                      dateFormat='dd/MM/yyyy' // Customize date format as needed
                                      />
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {tripType === 'return' && (
                <div width="1,,,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 kRXxeM eplQeC">
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                    <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 sc-20db91c2-0 bXyETw gSCCYN">
                        <div color="background.lightest" class="Box-pcln-design-system__sc-n9h3nv-0 hhIGyZ">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 kFfTeO">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <button width="1" color="primary" aria-expanded="false" tabindex="0" type="button"  role="combobox" aria-labelledby="id-1707298637790" aria-invalid="false" data-value="false" 
                                        class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 gCTxcz sc-2e703bfe-1 hyaGGX">
                                  <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gLnKRh EZYWO">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iqzbxV Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                    </svg>
                                    <DatePicker
                                      selected={onewayRDate}
                                      onChange={handleOnewayRDate}
                                      minDate={new Date()}
                                      placeholderText={t('Return')}
                                      disabled={tripType === 'oneway'}
                                      dateFormat='dd/MM/yyyy' // Customize date format as needed
                                      />
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
                <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 bLHgBL" style={{width:'33%'}}>
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                    <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                      <div>
                        <div data-testid="traveler-selection" class="Box-pcln-design-system__sc-n9h3nv-0 Container-pcln-design-system__sc-1tak897-0 drMhZA eibkaw">
                          <div color="background.lightest" data-traveler-selection="true" title="Traveler Selection" width="1" class="Box-pcln-design-system__sc-n9h3nv-0 sc-20db91c2-0 eyMQxx gSCCYN sc-8f40b904-2 bzlDSv">
                            <button type="button" color="text.lightest" width="1" aria-label="traveler-selection-control-panel" aria-expanded="false" aria-controls="traveler-selection-control-panel" aria-invalid="false" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 iOWVqH sc-486fb560-0 fyECMh" onClick={toggleDetailModal} >
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv EZYWO">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iqzbxV User__SvgUser-sc-tdftug-0 jIfocp">
                                  <path d="M12 12c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0 2c-2.7 0-8 1.3-8 4v2h16v-2c0-2.7-5.3-4-8-4z"></path>
                                </svg>{onewayPData.adults} {t('Adults')} . {onewayPData.childs} {t('Children')} . {onewayPData.infants} {t('Infant')}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ" style={{width:'33%'}}>
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                    <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 FormField__DisableableBox-pcln-design-system__sc-tx1s9e-0 WjkcY">
                        <label for="cabin-class-select" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 bhbsQA" 
                        style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 12px)'}}>Cabin Class</label>
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                          <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw llNdfK">
                            <select id="cabin-class-select"  class="Select__SelectBase-pcln-design-system__sc-m07nxd-1 Ijua sc-856c5fd8-0 hQyEIt" font-size="2,,1" 
                                    value={onewaySelectChangecabin} onChange={handleOneWaySelectchangecabin}
                                     style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1s', paddingTop: '20px', paddingBottom: '8px'}}>
                                <option selected value='Y'>{t('Economy')}  </option>
                                <option  value='no'> {t('All')} </option>
                                <option value='C'>{t('Business')}</option>
                                <option value='F'>{t('First')}</option>
                                <option value='S'>{t('Premium Economy')}</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="text.light" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 eAUkiv ChevronDown__SvgChevronDown-sc-1jf6nih-0 Select__ClickableIcon-pcln-design-system__sc-m07nxd-0 hCAiHp bWdZJj">
                              <path d="M7.4 8l4.6 4.6L16.6 8 18 9.4l-6 6-6-6L7.4 8z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {tripType === 'round' && (
                <div width="1,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jmCKNm brHOWu" style={{width:'33%'}}>
                  <div width="0,,1" class="Box-pcln-design-system__sc-n9h3nv-0 fNHOAf">
                    <div height="56px" class="Box-pcln-design-system__sc-n9h3nv-0 fYFENW">
                      <div height="100%" color="highlight.light" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ecPXvd llNdfK">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="highlight.shade" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bkdMKt Discount__SvgDiscount-sc-1s5abed-0 fkoOZw">
                            <path d="M23 12l-2.4 2.7.3 3.5-3.6.8-1.9 3-3.4-1.4L8.6 22l-1.9-3-3.6-.8.3-3.5L1 12l2.4-2.7-.3-3.5L6.7 5l1.9-3L12 3.4 15.4 2l1.9 3 3.6.8-.3 3.5L23 12zm-10.8-.6c-1.3-.3-1.8-.7-1.8-1.3 0-.6.6-1.1 1.6-1.1s1.4.5 1.5 1.2h1.3c0-1-.7-1.9-1.9-2.2V6.7h-1.8V8c-1.1.2-2 1-2 2.1 0 1.3 1.1 2 2.8 2.4 1.5.4 1.8.9 1.8 1.4 0 .4-.3 1-1.6 1-1.2 0-1.7-.5-1.8-1.2H9c.1 1.3 1 2 2.2 2.2v1.3H13V16c1.1-.2 2.1-.9 2.1-2.1-.1-1.6-1.5-2.2-2.9-2.5z"></path>
                          </svg>
                          <div color="highlight.shade" font-size="0,,,,1" class="Text-pcln-design-system__sc-1xtb652-0 sc-9ea25354-0 jNumaf AlgmB">Bundle + Save</div>
                        </div>
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv hovrrF" style={{flex: '1 0 auto'}}>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 bTJBky">
                            <label font-size="0" for="add-a-hotel-checkbox" color="text" font-weight="normal" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 iltVqX">
                              <div color="primary" class="Checkbox__CheckBoxWrapper-pcln-design-system__sc-1vxg344-0 hxFRtH">
                                <input type="checkbox" aria-label="Add a hotel" id="add-a-hotel-checkbox" data-testid="add-a-hotel-checkbox"  size="20" color="primary" role="checkbox" aria-checked="false" data-indeterminate="false" class="Checkbox__StyledInput-pcln-design-system__sc-1vxg344-1 iIhBdY" value="STAY" />
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="checked" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxChecked__SvgBoxChecked-sc-xar6qz-0 fQHOkP">
                                  <path d="M6 3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm4 14l9-8.6L17.6 7 10 14.3l-3.6-3.5L5 12.2l5 4.8z"></path>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="indeterminate" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxMinus__SvgBoxMinus-sc-m2y8sx-0 fWKCvU">
                                  <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm1 8v2h10v-2H7z"></path>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="empty" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxEmpty__SvgBoxEmpty-sc-tc3bem-0 ehVqsH">
                                  <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3z"></path>
                                </svg>
                              </div> Add a hotel 
                            </label>
                          </div>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 bTJBky">
                            <label font-size="0" for="add-a-car-checkbox" color="text" font-weight="normal" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 iltVqX">
                              <div color="primary" class="Checkbox__CheckBoxWrapper-pcln-design-system__sc-1vxg344-0 hxFRtH">
                                <input type="checkbox" aria-label="Add a car" id="add-a-car-checkbox" data-testid="add-a-car-checkbox" size="20" color="primary" role="checkbox" aria-checked="false" data-indeterminate="false" class="Checkbox__StyledInput-pcln-design-system__sc-1vxg344-1 iIhBdY" value="DRIVE" />
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="checked" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxChecked__SvgBoxChecked-sc-xar6qz-0 fQHOkP">
                                  <path d="M6 3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm4 14l9-8.6L17.6 7 10 14.3l-3.6-3.5L5 12.2l5 4.8z"></path>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="indeterminate" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxMinus__SvgBoxMinus-sc-m2y8sx-0 fWKCvU">
                                  <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm1 8v2h10v-2H7z"></path>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="empty" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxEmpty__SvgBoxEmpty-sc-tc3bem-0 ehVqsH">
                                  <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3z"></path>
                                </svg>
                              </div> Add a car 
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
                <div width="1,,1,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 hnrfup" style={{width:'33%'}}>
                  <button width="1" type="button" scale="1.02" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 hwEbXr sc-266892e-0 kULWIA" color="primary" onClick={()=>OneWayapicall(1)}>
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">{t('Modify Your Flight')}</div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModifyFlightSearch
