import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import HotelDetail from '../../Pages/Hotels/HotelDetail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faGlobe,
  faCheck,
  faArrowRight,
  faInfoCircle,
  faLocationDot
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import moment from 'moment'
import iLoading from '../../Images/Loading/Loader.gif'
import Loading from '../Loading/Loader'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../GlobalData/GlobalData'
import { fetchHotelDetail } from '../../Redux/Actions/actions'

function HotelCard4 ({ hotelid }) {
  const dispatch = useDispatch()
  var endpoint = ApiEndPoint()
  var apitoken = Hotelapitoken()
  const [searchData, setSearchData] = useState({
    checkin: '',
    checkout: '',
    adult: 0,
    child: 0
  })
  var filteredHotels = []
  const navigate = useNavigate()
  const [Loadingpage, setLoadingpage] = useState(false)
  const [visibleHotels, setVisibleHotels] = useState(10)
  const [hotelDetails, setHotelDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [baseCName, setBaseCName] = useState('GBP')
  const [baseCurreny, setBaseCurreny] = useState([])
  const [hotelsListing, setHotelsListing] = useState([])
  const [showPrice, setShowPrice] = useState(true)
  const hotels = useSelector(state => state.hotels.hotels);
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)
  const CurrenyRates = useSelector(state => state.hotels.Curreny)
  const GBPCurrenyRates = useSelector(state => state.hotels.AllCurreny)

  useEffect(() => {
  }, [ReduxSearchData])
  useEffect(() => {
    if (hotelid !== '') {
      setLoadingpage(true)
      if (typeof hotelid === 'number') {
        filteredHotels = hotels.hotels_list.filter(
          x => x.hotel_id === Number(hotelid)
        )
        setHotelsListing(filteredHotels)
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setLoadingpage(false)
      } else if (hotelid.type === 'Stars') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          const hotelRating = hotel.stars_rating
          if (hotelRating !== '') {
            return Object.keys(hotelid).some(
              ratingKey => Number(hotelRating) === Number(hotelid[ratingKey])
            )
          }
        })
        setHotelsListing(filteredHotels)
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setLoadingpage(false)
      } else if (hotelid.type === 'meal') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          // Check if any room in the hotel's rooms_options array has the specified boardtype
          return hotel.rooms_options.some(room => {
            for (const mealKey in hotelid) {
              if (room.board_id === hotelid[mealKey]) {
                return true // Found a match, include the hotel
              }
            }
            return false // No match found for any meal key
          })
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setLoadingpage(false)
      } else if (hotelid.type === 'facility') {
        const filteredHotels = []
        hotels.hotels_list.forEach(hotel => {
          Facilityfilter(hotel.hotel_id, hotel.hotel_provider, metaData => {
            // Check if the hotel has the desired facilities (e.g., wifi and safe).
            for (const mealKey in hotelid) {
              if (metaData.facilities.includes(hotelid[mealKey])) {
                filteredHotels.push(hotel)
              }
            }
          })
        })
        setLoadingpage(false)
        setHotelDetails({})
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setHotelsListing(filteredHotels)
      } else if (hotelid.type === 'price') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          const price = hotel.min_price
          return price >= hotelid.min && price <= hotelid.max
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setLoadingpage(false)
      }
    }
  }, [hotelid])
  const Facilityfilter = async (hotelId, provider, callback) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }
    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      callback(response.data.details_data)
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    setSearchData(prevdata => ({
      ...prevdata,
      adult: ReduxSearchData.adult,
      child: ReduxSearchData.child,
      checkin: moment(ReduxSearchData.check_in).format('Do MMM '),
      checkout: moment(ReduxSearchData.check_out).format('Do MMM ')
    }))
    // Add a scroll event listener to the window.
    window.addEventListener('scroll', handleScroll)
    return () => {
      // Remove the scroll event listener when the component unmounts.
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (hotels && hotels.hotels_list && hotels.hotels_list.length > 0) {
        var curreny = hotels.hotels_list[0].hotel_curreny;
        if (curreny !== undefined) {
            AllCurreny(curreny);
          } else {
            console.error("hotel_curreny is undefined");
            console.log(hotels.hotels_list[0]);
        }
    } else {
        console.error("hotels or hotels_list is undefined or empty");
    }
}, [hotels]);

  const RoomDetailPage = async (id, index) => {
    const hotelRoomdetail = hotels.hotels_list.filter(
      item => item.hotel_id == id
    )
    setLoadingpage(true)
    try {
      const data = {
        token: apitoken,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      // _Live
      console.log(data);
      const response = await Axios.post(
        endpoint + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
            'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
          }
        }
      ) 
        console.log(data);
        console.log(response)
      // Handle the API response here
      dispatch(fetchHotelDetail(response.data.hotel_details))
      navigate(`/hotel_detail/${id}`, { state: { index } })
      setLoadingpage(false)
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };

  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }
    console.log(data);
    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      console.log(response.data);
      setHotelDetails(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };
  
  const loadMoreHotels = () => {
    setVisibleHotels(prevVisibleHotels => prevVisibleHotels + 10)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    hotels.hotels_list
      .slice(visibleHotels - 10, visibleHotels)
      .forEach(hotel => {
        if (!hotelDetails[hotel.hotel_id]) {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        }
      })
  }, [visibleHotels])

  const handleScroll = () => {
    // Check if the user has scrolled to the bottom of the page
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.offsetHeight
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (documentHeight - (windowHeight + scrollTop) < 800) {
      // Load more hotels when the user reaches the bottom
      if (!loading) {
        loadMoreHotels()
      }
    }
  }
  const renderPrice = price => {
    if (CurrenyRates === undefined) {
      const gbpprice = baseCurreny[baseCName] // Use square brackets to access the property
      var baseprice = (Number(gbpprice) * Number(price)).toFixed(0)
    } else {
      var select123 = CurrenyRates.selectedcurreny
      const gbpprice = baseCurreny[baseCName]
      var baseprice1 = (Number(gbpprice) * Number(price)).toFixed(0)
      const gbpprice2 = GBPCurrenyRates.conversion_rates[select123] // Use square brackets to access the property
      var baseprice = (Number(gbpprice2) * Number(baseprice1)).toFixed(0)
    }
    return baseprice
  };

  const AllCurreny = curreny => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + curreny, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        // Handle the response data
        setBaseCurreny(response.data.conversion_rates)
        localStorage.setItem('HotelCurreny', JSON.stringify(response.data))
      })
      .catch(error => {
        // Handle errors here
        setShowPrice(false)
        console.error(error)
      })
  };
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    return finalpricemarkup
  };
console.log(hotelDetails);
  return (
    <>
      {Loadingpage && <Loading />}
      <div className='px-4'>
        {hotelid === '' ? (
          <div className='filter-page__content'>
            <div className='filter-item-wrapper' id='tours_filter'>
                <div class="Box-pcln-design-system__sc-n9h3nv-0 heoKlL">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv"></div>
                    <h2 font-size="4" class="Text-pcln-design-system__sc-1xtb652-0 egUcfp">People also liked</h2>
                    <div data-testid="theme-provider" class="ThemeProvider__Base-pcln-design-system__sc-1502h3l-0 iDmCsd">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 dMzUTD">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 ContentStrip__StripContainer-sc-2nmqyv-0 iOkPFo dgoPHI dckIEK" height="410px">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 djhuzv dgoPHI" style={{overflow: 'hidden'}}>
                              <div style={{height: '100%'}}>
                                <div   style={{height: '100%'}}>
                                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ContentStrip__Rail-sc-2nmqyv-1 bXyETw buRICd">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 ContentStrip__ContentContainer-sc-2nmqyv-2 djhuzv hTPOWJ row">
                                    {hotels.hotels_list.slice(0, 4).map((item, index) => (
                                      <div key={item.hotel_id} data-testid="Content_Strip_Htl_Wrapper" style={{height: '100%'}} className='col-md-3 px-1'>
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 ContentStrip__TileContainer-sc-2nmqyv-3 dyKWWO dKVIXv">
                                          <div color="background.lightest" width="280,,,300" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 DealMatchCarousel__BoxShadowCard-sc-ftwwp5-0 muNWO bBYTmp iwjFUj">
                                            <div class="Box-pcln-design-system__sc-n9h3nv-0 StackedLayout__ResponsiveWrapper-sc-8lfxb5-0 byYMpc boSRrL">
                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jFEgDN hjHzq">
                                                <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                                                  <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 gLnKRh dgoPHI">
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 eMuljS brHOWu" width="1" height="100%">
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 ImageSection__DynamicAbsolute-sc-8eqgic-0 djhuzv cCaSNq lnfoQs"></div>
                                                        <div class="BackgroundImage-pcln-design-system__sc-1eaij4d-0 ImageDisplay__ClickableImage-sc-1q7kzz5-1 gDxjVM iTzpZe" width="1" data-testid="CLICKABLE_IMAGE_WRAPPER">
                                                          <div data-testid="HTL_LISTING_SINGLE_IMAGE" width="1" class="BackgroundImage-pcln-design-system__sc-1eaij4d-0 ImageDisplay__BGImage-sc-1q7kzz5-0 jKElEr guvvK">
                                                            {hotelDetails[item.hotel_id] &&
                                                                hotelDetails[item.hotel_id].details_data &&
                                                                hotelDetails[item.hotel_id].details_data.image ? (
                                                                 <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 gLnKRh dgoPHI">
                                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 eMuljS brHOWu" width="1" height="100%">
                                                                      <div class="BackgroundImage-pcln-design-system__sc-1eaij4d-0 ImageDisplay__ClickableImage-sc-1q7kzz5-1 gDxjVM" width="1" data-testid="CLICKABLE_IMAGE_WRAPPER">
                                                                        <div class="InlineImageSwipe__Container-sc-zl6cca-0 kwHIxs">
                                                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 PhotoSwipe__CarouselContainer-sc-1cx2tme-2 juFVep dgoPHI erKYis" data-testid="CAROUSEL_CONTAINER" width="1" role="region" aria-label="Photo carousel">
                                                                            <div>
                                                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 PhotoCarousel__PhotoCarouselAbsolute-sc-engw3c-0 hLLYkz dpgIZ eNvHXq">
                                                                                <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                                                                    <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                                                                    <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ hero-image" src={
                                                                                            hotelDetails[item.hotel_id].details_data.image
                                                                                        } alt="Exterior View" data-testid="MainImage" />
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                 </div>
                                                                ) : (
                                                                <img src={iLoading} />
                                                                )}
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 StackedLayout__Box2-sc-8lfxb5-1 dLgWAY brHOWu kwTBvr">
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 SummarySection__SummaryFlex-sc-17w5fnt-0 gLnKRh GVgVi ctOiCE" height="100%">
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm SummarySection__MarginedSummaryHeader-sc-17w5fnt-1 dlpexW SummarySection__MarginedSummaryHeader-sc-17w5fnt-1 dlpexW">
                                                            <h3 class="Text-pcln-design-system__sc-1xtb652-0 hABLsV" font-weight="bold">
                                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                                                <a font-size="1,,,3" aria-label="Rose Palace Hotel" color="text.dark" data-autobot-element-id="HTL_LIST_LISTING_COMPONENT_HOTEL_NAME" font-weight="bold" href="" target="_blank" rel="noopener" onClick={() => RoomDetailPage(item.hotel_id, index)}
                                                                    class="Link-pcln-design-system__sc-1eidvea-0 cOa-dGf Link-pcln-design-system__sc-1eidvea-0 CardTitle__CardTitleText-sc-9ojcvj-0 cOa-dGf gXfgSv Link-pcln-design-system__sc-1eidvea-0 CardTitle__CardTitleText-sc-9ojcvj-0 cOa-dGf gXfgSv">{item.hotel_name}</a>
                                                                </div>
                                                            </h3>
                                                        </div>
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm SummarySection__MarginedSummaryBody-sc-17w5fnt-2 jJGxce SummarySection__MarginedSummaryBody-sc-17w5fnt-2 jJGxce">
                                                        {hotelDetails[item.hotel_id] && (
                                                        <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AttributesRow__FlexContainer-sc-5v5rpd-1 bXyETw llNdfK jfdNek ProximityRow__ProximityAttributesRow-sc-qml0uj-0 jlaShT ProximityRow__ProximityAttributesRow-sc-qml0uj-0 jlaShT" font-size="0">
                                                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 NeighborhoodRow__NeighborhoodRowWrapper-sc-aotlqz-1 djhuzv llNdfK dDlVqX">
                                                            <div display="flex" color="text.base" class="Text-pcln-design-system__sc-1xtb652-0 jgAEOo"></div>
                                                            <div display="flex" color="primary" class="Text-pcln-design-system__sc-1xtb652-0 hxngiQ">
                                                                <div color="text.light" font-weight="bold" font-size="11px,,0" title="" class="Text-pcln-design-system__sc-1xtb652-0 NeighborhoodRow__AntiAliasedText-sc-aotlqz-0 hvUizq emqyYq">{hotelDetails[item.hotel_id].details_data.address}</div>
                                                            </div>
                                                            </div>
                                                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AttributesRow__AttributesRowCallToAction-sc-5v5rpd-0 djhuzv brHOWu eUIEFT"></div>
                                                        </div>
                                                        )}
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 ecPbyy">
                                                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ibbmoM llNdfK">
                                                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AttributesRow__FlexContainer-sc-5v5rpd-1 bXyETw llNdfK jfdNek AmenitiesRow__HiddenOverflowAttributesRow-sc-1g2qerr-1 cgKvdi AmenitiesRow__HiddenOverflowAttributesRow-sc-1g2qerr-1 cgKvdi" font-size="0">
                                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 idAHBB brHOWu">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bUvHvR Check__SvgCheck-sc-9psb6r-0 jQwtKB">
                                                                    <path d="M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5 8.6 15.6z"></path>
                                                                </svg>
                                                                </div>
                                                                <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="Text-pcln-design-system__sc-1xtb652-0 Truncate-pcln-design-system__sc-1q95e1k-0 iJdXHC gnvruv">Free Internet, Free Parking, Health Services.</div>
                                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Hide-pcln-design-system__sc-i482tf-0 djhuzv jvrnLN">
                                                                <div color="primary" font-weight="bold" data-autobot-element-id="HTL_D_LIST_HOTEL_AMENITIES_LINK" class="Text-pcln-design-system__sc-1xtb652-0 AmenitiesRow__CaptionText-sc-1g2qerr-0 TVAJB gFusSs"></div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 DealsRow__MarginedBox-sc-1rjxnd8-6 djhuzv hMJHTx"></div>
                                                        </div>
                                                    </div>
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm SummarySection__MarginedSummaryFooter-sc-17w5fnt-3 rCVFu SummarySection__MarginedSummaryFooter-sc-17w5fnt-3 rCVFu">
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw llNdfK" width="1">
                                                            <div font-size="0,,1" class="Text-pcln-design-system__sc-1xtb652-0 fejXoU">
                                                            <div height="20px,,24px" data-autobot-element-id="HTL_D_LIST_GUEST_SCORE_ICON" font-weight="bold" color="alert" class="Box-pcln-design-system__sc-n9h3nv-0 RatingBadge-pcln-design-system__sc-1haimsy-0 GuestSummary__SummaryRatingBadge-sc-115gfc6-0 bsMzZY blyaJX bYmMxZ">
                                                                <div font-size="0,,1" class="Text-pcln-design-system__sc-1xtb652-0 GuestSummary__RatingText-sc-115gfc6-1 hybSXr ivHQqz">8.3</div>
                                                            </div>
                                                            </div>
                                                            <div width="1" class="Text-pcln-design-system__sc-1xtb652-0 Truncate-pcln-design-system__sc-1q95e1k-0 gVLirO gnvruv">
                                                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 GuestSummary__TransitionWrapper-sc-115gfc6-5 BJEdV llNdfK dvniSq">
                                                                <div font-weight="regular" font-size="11px,,0" data-autobot-element-id="HTL_D_LIST_VERFIED_GUEST_REVIEWS_LINK" class="Text-pcln-design-system__sc-1xtb652-0 GuestSummary__ReviewsText-sc-115gfc6-2 dPpMDv emUQqf">3269 Ratings</div>
                                                                <hr color="border" class="Divider-pcln-design-system__sc-10weutl-0 GuestSummary__Separator-sc-115gfc6-4 kwmkzy dKNtyI" />
                                                                <div font-weight="regular" font-size="11px,,0" data-autobot-element-id="GUEST_REVIEWS_STAR_LEVEL" class="Text-pcln-design-system__sc-1xtb652-0 Truncate-pcln-design-system__sc-1q95e1k-0 GuestSummary__StarLevelText-sc-115gfc6-3 dPpMDv gnvruv bhZOkw">3-Star Hotel</div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div width="1" height="auto" data-testid="STACKEDLAYOUT_SECTION3" class="Box-pcln-design-system__sc-n9h3nv-0 StackedLayout__Box3-sc-8lfxb5-2 bDvVvx hgDYXC">
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 MinRateSection__MinRateFlex-sc-18x9jjj-2 qcvUI foEYhm ligNCH" height="auto,,,100%">
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv nfzvG MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 erwWjn MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 erwWjn">
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv gsiNzy"></div>
                                                    </div>
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm MinRateSection__MinRatePriceWrapper-sc-18x9jjj-1 gdTwDF MinRateSection__MinRatePriceWrapper-sc-18x9jjj-1 gdTwDF">
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv hVbGFU">
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ipCoSW">
                                                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK"></div>
                                                            <span color="primary.dark" font-weight="bold" font-size="0,,,2" class="Text__Span-pcln-design-system__sc-1xtb652-1 GenericPrice__Currency-sc-10yzufv-0 jtgGRV jRHxKY">$</span>
                                                            <span color="primary.dark" font-size="4,,,5" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 exIBst">26</span>
                                                        </div>
                                                        </div>
                                                        <div font-size="0" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 MinRatePrice__NoWrapText-sc-1jfdkhu-1 gya-Dwn jErPHL"> {item.hotel_curreny}{' '}
                                                            {calculateMarkup(
                                                            item.min_price,
                                                            item.admin_markup,
                                                            item.admin_markup_type,
                                                            item.customer_markup,
                                                            item.customer_markup_type
                                                            ).toFixed(2)}</div>
                                                        <div data-testid="button-wrapper" class="Box-pcln-design-system__sc-n9h3nv-0 Hide-pcln-design-system__sc-i482tf-0 dejomW jvrnLN"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                     ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
              {/* {hotels.hotels_list.slice(0, visibleHotels).map((item, index) => (
                <div key={item.hotel_id} className='row'>
                  <div className='col-md-12 mb-4'>
                    <div className='row parent_row ipmBhE hZjqDq fIdRda'>
                      <div className='col-md-6'>
                        <p className='card-star'>
                          {item.stars_rating === '' ? (
                            <span className='fw-bold'>No Rating</span>
                          ) : (
                            Array(item.stars_rating)
                              .fill(0)
                              .map((_, index) => (
                                <i key={index} className='fa fa-star'>
                                  <FontAwesomeIcon icon={faStar} />
                                </i>
                              ))
                          )}
                        </p>
                        
                        <div class='item-address' style={{ color: 'green' }}>
                          <i class='awe-icon awe-icon-marker-2'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>{' '}
                          {item.rooms_options.length} Rooms Available{' '}
                        </div>
                      </div>
                      <div className='col-md-3 casfcL p-2'>
                        <div className='text-center card-price-section hIzCYw fUSPHi'>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv iysiIk MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kJGIQs">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv MinRateMerchandising__PaddedMerchandisingTypes-sc-1tcss2r-0 XehgS"></div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gHDiTU brHOWu" style={{textAlign:'center'}}>
                                <div color="pricePrimary.base" font-weight="bold" class="ijknVs text-center"> 10% Off</div>
                              </div>
                            </div>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ezJlZN dBEOEE">
                              <div font-size="0" color="text.light" data-unit-id="SECTION_SUBTITLE" class="Text-pcln-design-system__sc-1xtb652-0 SavingsDisplay__SubTitleText-sc-1o04y33-0 HkSpC csvZTM">Savings last for a limited time only</div>
                            </div>
                          </div>
                          <div className='ggTnOm pb-2'>
                            <h6 className='text-center p-view-detail'>Map View</h6>
                            <div className='price text-center p-card-price'>
                              {showPrice ? (
                                <h6>
                                  <super>
                                    {CurrenyRates === undefined
                                      ? baseCName
                                      : CurrenyRates.selectedcurreny}{' '}
                                    {renderPrice(
                                      calculateMarkup(
                                        item.min_price,
                                        item.admin_markup,
                                        item.admin_markup_type,
                                        item.customer_markup,
                                        item.customer_markup_type
                                      )
                                    )}
                                  </super>
                                </h6>
                              ) : (
                                <h6>
                                  <super>
                                    {item.hotel_curreny}{' '}
                                    {calculateMarkup(
                                      item.min_price,
                                      item.admin_markup,
                                      item.admin_markup_type,
                                      item.customer_markup,
                                      item.customer_markup_type
                                    ).toFixed(2)}
                                  </super>
                                </h6>
                              )}
                            </div>
                            <button className='my-1 kOMYfA' onClick={() => RoomDetailPage(item.hotel_id, index)}>Book Now </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        ) : (
          <div className='filter-page__content'>
            <div className='filter-item-wrapper' id='tours_filter'>
              {hotelsListing.slice(0, visibleHotels).map((item, index) => (
                <div key={item.hotel_id} className='row'>
                  <div className='col-md-12 mb-4'>
                    <div className='row parent_row'>
                      <div className='col-md-3 item-from p-0'>
                        {hotelDetails[item.hotel_id] &&
                        hotelDetails[item.hotel_id].details_data &&
                        hotelDetails[item.hotel_id].details_data.image ? (
                          <div className=''>
                            <img
                              className='tour-img'
                              src={
                                hotelDetails[item.hotel_id].details_data.image
                              }
                              alt=''
                            />
                          </div>
                        ) : (
                              <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 gLnKRh dgoPHI">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 eMuljS brHOWu" width="1" height="100%">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 ImageSection__DynamicAbsolute-sc-8eqgic-0 djhuzv cCaSNq cPqfpR">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djwVDp" width="1">
                                      <div aria-label="Click to open popover with more information" type="button" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                        <button aria-label="Sign in to save" data-testid="HEART_SAVE_CONTAINER" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV HeartSaveButton__HeartSaveContainer-sc-1o6sggt-0 hItZFh" color="primary">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                            <div data-testid="ANIMATED_HEART_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 AnimatedHeartIcon__AnimatedHeartContainer-sc-aao2eh-0 djhuzv jEqlGx">
                                              <svg viewBox="0 0 560 560" class="heart-svg">
                                                <path class="heart" transform="translate(25, 25)" d="M256,96.5l-19.7-20.3C186.1,24.3,104.5,15.9,49.7,62.6c-62.8,53.6-66.1,149.8-9.9,207.9l193.5,199.8 c12.5,12.9,32.8,12.9,45.3,0l193.5-199.8c56.3-58.1,53-154.3-9.8-207.9l0,0C407.5,15.9,326,24.3,275.7,76.2L256,96.5z"></path>
                                              </svg>
                                              <span class="heart-burst"></span>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{borderRadius:'16px 0px 0px 16px'}} class="BackgroundImage-pcln-design-system__sc-1eaij4d-0 ImageDisplay__ClickableImage-sc-1q7kzz5-1 gDxjVM eaVPxX" width="1" data-testid="CLICKABLE_IMAGE_WRAPPER">
                                    <div style={{borderRadius:'16px 0px 0px 16px'}} class="InlineImageSwipe__Container-sc-zl6cca-0 kwHIxs">
                                      <div style={{borderRadius:'16px 0px 0px 16px'}} class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 PhotoSwipe__CarouselContainer-sc-1cx2tme-2 juFVep dgoPHI erKYis" data-testid="CAROUSEL_CONTAINER" width="1" role="region" aria-label="Photo carousel">
                                        <div>
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 PhotoCarousel__PhotoCarouselAbsolute-sc-engw3c-0 hLLYkz dpgIZ eNvHXq">
                                            <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                              <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                              <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ hero-image" src="https://assets.pclncdn.com/agodastatic/hotelImages/23670965/0/bc0628a8bde8d39af680e46b43e21c50.jpg?width=247&amp;crop=1:1&amp;dpr=2&amp;format=jpg&amp;opto&amp;auto=avif,webp" alt="Exterior View" data-testid="MainImage" />
                                            </div>
                                            <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                              <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                              <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ" src="https://assets.pclncdn.com/bstatic/xdata/images/hotel/405583042.jpg?k=ee37e72cbf42d1d16f69fe832fd778faa4ad6fa221ea8052b5c0eef005eb88c5&amp;o=&amp;width=247&amp;crop=1:1&amp;dpr=2&amp;format=jpg&amp;opto&amp;auto=avif,webp" alt="Facilities" data-testid="MainImage" />
                                            </div>
                                            <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                              <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                              <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ" src="https://assets.pclncdn.com/agodastatic/hotelImages/23670965/0/4a004f3454cf7912e968e5b5ee64229d.jpg?width=247&amp;crop=1:1&amp;dpr=2&amp;format=jpg&amp;opto&amp;auto=avif,webp" alt="Restaurant/Places To Eat" data-testid="MainImage" />
                                            </div>
                                            <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                              <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                              <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ" src="https://assets.pclncdn.com/agodastatic/hotelImages/25967388/-1/7f8fca59941b10856c1968e2b5da6c20.jpg?width=247&amp;crop=1:1&amp;dpr=2&amp;format=jpg&amp;opto&amp;auto=avif,webp" alt="Lobby or Reception" data-testid="MainImage" />
                                            </div>
                                            <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                              <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                              <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ" src="https://assets.pclncdn.com/bstatic/xdata/images/hotel/316484212.jpg?k=5c1c07c023f9ba3285dd236ffb9376291df12c97a28eede68dac95f1ee38031f&amp;o=&amp;width=247&amp;crop=1:1&amp;dpr=2&amp;format=jpg&amp;opto&amp;auto=avif,webp" alt="Floor Plan" data-testid="MainImage" />
                                            </div>
                                          </div>
                                        </div>
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__CarouselContainer-sc-9e4onp-0 djhuzv brHOWu dRiUBG PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa">
                                          <div data-unit-id="CAROUSEL_SLIDER" class="CarouselPagination__StyledCarousel-sc-9e4onp-1 gzvUFf">
                                            <form>
                                              <div data-unit-id="PAGINATION_DOTS_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__StyledFlex-sc-9e4onp-2 djhuzv ipCoSW mDstq">
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #1" aria-current="true" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 gfMkOK">
                                                    <div color="text.lightest" width="8px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 cefYII hXamFu dlexSF"></div>
                                                  </button>
                                                </div>
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #2" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                    <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                  </button>
                                                </div>
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #3" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                    <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                  </button>
                                                </div>
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #4" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                    <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                  </button>
                                                </div>
                                                <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                  <button aria-label="Go to Image #5" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                    <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                  </button>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          // <ShimmerThumbnail height={250} />
                        )}
                      </div>
                      <div className='col-md-6'>
                        <h5 className='card-title mt-2'>
                          <a
                            onClick={() => RoomDetailPage(item.hotel_id, index)}
                            className='p-card-title'
                          >
                            {item.hotel_name}
                          </a>
                        </h5>
                        <h6 className='departure-date mb-0'>
                          {searchData.checkin}{' '}
                          <FontAwesomeIcon icon={faArrowRight} />{' '}
                          {searchData.checkout}
                        </h6>
                        <p className='card-star'>
                          {item.stars_rating === '' ? (
                            <span className='fw-bold'>No Rating</span>
                          ) : (
                            Array(item.stars_rating)
                              .fill(0)
                              .map((_, index) => (
                                <i key={index} className='fa fa-star'>
                                  <FontAwesomeIcon icon={faStar} />
                                </i>
                              ))
                          )}
                        </p>
                        {hotelDetails[item.hotel_id] && (
                          <div class='item-address'>
                            <i class='awe-icon awe-icon-marker-2'>
                              <FontAwesomeIcon icon={faGlobe} />
                            </i>{' '}
                            {hotelDetails[item.hotel_id].details_data.address}{' '}
                          </div>
                        )}
                         {hotelDetails[item.hotel_id] ? (
                          <div class='row'>
                            {hotelDetails[
                              item.hotel_id
                            ].details_data.facilities.map((facility, index) => (
                              <div
                                key={index}
                                className=' col-sm-4 col-4 col-md-4 col-lg-4 mt-1'
                              >
                                <div class='single-tour-feature d-flex align-items-center mb-3'>
                                  <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                    <i class='fas fa-check'>
                                      <FontAwesomeIcon icon={faCheck} />
                                    </i>
                                  </div>
                                  <div class='single-feature-titles'>
                                    <p class='title fw-bold'>{facility}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ):(
                        <div className='placeholder-glow'>
                          <span className='placeholder col-12'></span>
                          <span className='placeholder col-12'></span>
                          <span className='placeholder col-6'></span>

                        </div>
                        )}
                        <div class='item-address' style={{ color: 'green' }}>
                          <i class='awe-icon awe-icon-marker-2'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>{' '}
                          {item.rooms_options.length} Rooms Available{' '}
                        </div>
                      </div>
                      <div className='col-md-3 casfcL p-2'>
                        <div className='text-center card-price-section hIzCYw fUSPHi'>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv iysiIk MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kJGIQs">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv MinRateMerchandising__PaddedMerchandisingTypes-sc-1tcss2r-0 XehgS"></div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gHDiTU brHOWu" style={{textAlign:'center'}}>
                                <div color="pricePrimary.base" font-weight="bold" class="ijknVs text-center"> 10% Off</div>
                              </div>
                            </div>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ezJlZN dBEOEE">
                              <div font-size="0" color="text.light" data-unit-id="SECTION_SUBTITLE" class="Text-pcln-design-system__sc-1xtb652-0 SavingsDisplay__SubTitleText-sc-1o04y33-0 HkSpC csvZTM">Savings last for a limited time only</div>
                            </div>
                          </div>
                          <div className='ggTnOm pb-2'>
                            <h6 className='text-center p-view-detail'>Map View</h6>
                            <div className='price text-center p-card-price'>
                              {showPrice ? (
                                <h6>
                                  <super>
                                    {CurrenyRates === undefined
                                      ? baseCName
                                      : CurrenyRates.selectedcurreny}{' '}
                                    {renderPrice(
                                      calculateMarkup(
                                        item.min_price,
                                        item.admin_markup,
                                        item.admin_markup_type,
                                        item.customer_markup,
                                        item.customer_markup_type
                                      )
                                    )}
                                  </super>
                                  {/* <sub>PP</sub> */}
                                </h6>
                              ) : (
                                <h6>
                                  <super>
                                    {item.hotel_curreny}{' '}
                                    {calculateMarkup(
                                      item.min_price,
                                      item.admin_markup,
                                      item.admin_markup_type,
                                      item.customer_markup,
                                      item.customer_markup_type
                                    ).toFixed(2)}
                                  </super>
                                  {/* <sub>PP</sub> */}
                                </h6>
                              )}
                            </div>
                            <div className='time_length'>
                              <i className='fa fa-moon-o' aria-hidden='true'></i>
                              {searchData.adult}-Adults, {searchData.child}-Children
                              {/* <span className='tour_length'>12 Night</span> */}
                            </div>
                            <button className='my-1 kOMYfA' onClick={() => RoomDetailPage(item.hotel_id, index)}>Book Now </button>
                            <div style={{ fontSize: '11px' }}>
                              <FontAwesomeIcon
                                color='#bd1c1cc9'
                                icon={faInfoCircle}
                              />{' '}
                              Inclusive of VAT and Taxes
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default HotelCard4
